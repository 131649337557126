import { Injectable } from '@angular/core';
import { Logger, LoggerContext } from './logger.service';

/**
 * TODO: Doc Updates
 *
 * A simple logger implementation that just logs to the browser console
 */
@Injectable({
  providedIn: 'root'
})
export class ConsoleLogger extends Logger {

  constructor() {
    super();
  }

  setContext(context: LoggerContext) {}

  debug(...message: any) {
    // TODO: add logic to suppress debug messages in prod builds
    message.forEach(arg => console.log('[debug]', arg));
  }

  log(...message: any) {
    console.log(message);
  }

  warn(...message: any) {
    console.warn(message);
  }

  error(...message: any) {
    console.error(message);
  }
}
