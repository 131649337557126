import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { environment } from '../../environments/environment';

export const getCommonProps = (platform: Platform, device: Device): {} => {
    let commonProps = {};
    commonProps['appVersion'] = `${environment.APP_VERSION}`;

    if (platform.is('hybrid')) {
        commonProps['osVersion'] = device.version;
        commonProps['isWeb'] = 'false';
    } else {
        commonProps['isWeb'] = 'true';
    }

    commonProps['platform'] = getPlatform(platform);
    commonProps['shouldCountOccurrence'] = 'true';

    return commonProps;
};

export const getPlatform = (platform: Platform): string => {
    if (platform.is('mobileweb')) {
        return 'MobileWeb';
    } else if (platform.is('ios')) {
        return 'iOS';
    } else if (platform.is('android')) {
        return 'Android';
    } else if (platform.is('desktop')) {
        return 'Desktop';
    } else {
        return 'other';
    }
}