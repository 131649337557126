import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Logger } from '../util/logger/logger.service';
import { UserService } from '../services/user.service';
import { environment } from '../../environments/environment';
import { JobsService as CmbJobsApi } from '@bluecrew/crew-member-backend-angular-client';
import { Observable, from } from 'rxjs';
import { MetricsInterceptorService } from '../services/metrics-interceptor.service';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { USER_SESSION_UPDATE_KEY, USER_SESSION_UPDATE_TIME_MILLIS } from '../util/storage-util';
import { mergeMap } from 'rxjs/operators';
import { getPlatform } from '../util/analytics-util';

/**
 * Interceptor to intercept outgoing Http Requests and detect if they are CMOS requests
 * and optionally add additional information to the request before sending out
 */
@Injectable()
export class CmosInterceptor implements HttpInterceptor {
    constructor(private logger: Logger,
                private userService: UserService,
                private metrics: MetricsInterceptorService,
                private cmbJobsApi: CmbJobsApi,
                private platform: Platform,
                private storage: Storage) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.asyncIntercept(request, next));
    }

    async asyncIntercept(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
        const isCmosRoute = request.url.startsWith(this.cmbJobsApi.configuration.basePath);
        if (!isCmosRoute) {
            return next.handle(request).toPromise();
        }

        return from(this.getHeaders(request)).pipe(
            mergeMap(headers => {
                request = request.clone({
                    setHeaders: headers
                });
                return this.metrics.instrumentHttpRequest('cmos', request, next);
            }
        )).toPromise();
    }

    private async getHeaders(request: HttpRequest<any>) {
        const headers: Record<string, string> = {
            'x-bluecrew-version': `${environment.APP_VERSION}`,
            'x-bluecrew-hybrid-app': `${this.platform.is('hybrid')}`,
        };

        headers['x-bluecrew-platform'] = getPlatform(this.platform);

        const user = this.userService.currentUser;

        if (user) {
            const currentTime = new Date();
            await this.storage.get(USER_SESSION_UPDATE_KEY).then(lastSessionUpdate => {
                if (lastSessionUpdate === undefined || lastSessionUpdate === null || 
                currentTime.getTime() - new Date(lastSessionUpdate).getTime() >= USER_SESSION_UPDATE_TIME_MILLIS) {
                    headers['x-bluecrew-session-type'] = 'MOST_RECENT';
                    headers['x-bluecrew-session-date'] = `${currentTime.toISOString()}`;
                    headers['x-bluecrew-should-update-user-session'] = 'true';
                    this.storage.set(USER_SESSION_UPDATE_KEY, currentTime.toISOString());
                }
            });
        }

        if (user && !request.headers.has('Authorization')) {
            if (request.url.startsWith('http://localhost')) {
                headers['X-API-KEY'] = `${environment.CMOS_LOCAL_API_KEY}`;
                headers['X-USER-ID'] = await this.userService.getCurrentExternalUserId();
            } else {
                headers.Authorization = await this.userService.getAuthToken();
            }
        }

        request = request.clone({
            setHeaders: headers
        });

        return headers;
    }
}
