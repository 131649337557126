import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor() {}

  showNewMessage() {
    (window as any).Intercom('showNewMessage');
  }

  show() {
    (window as any).Intercom('show');
  }
}
