import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Injectable, NgZone, Inject } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { isEqual } from 'lodash-es';
import { mergeMap, distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User, SignUpRequest, UserInfo, IntercomInfo, UserType } from '../models/user';
import { Logger } from '../util/logger/logger.service';
import { Preconditions } from '../util/preconditions';
import {
    BasicInformationRequest,
    UserService as CmbUserApi,
    NoauthService as CmbNoAuthApi,
    PreferencesService as CmbPreferencesApi,
    LegalAcceptanceId,
    UserInformation,
    UserProfile,
    UserPushToken,
    UserSignupRequest,
    WorkPreferences,
    WorkPreferencesSchedule
  } from '@bluecrew/crew-member-backend-angular-client';
import { AppRoutingUtil } from '../util/app-routing-util';
import { AnalyticsPlugin } from '@plugins/analytics/analytics-plugin';
import { AuthPlugin } from '@plugins/auth/auth-plugin';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { getCMOSUrlUsingAuthToken, isUsingCognitoToken } from '../util/auth-util';

const V1_TOKEN_NAME = 'bc.bearer';
const USER_KEY = 'user';
const JOB_ID_KEY = 'jobId';
const JOB_IDS_KEY = 'jobIds';

/**
 * UserService is responsible for managing the auth tokens and user data associated with the currently
 * active crew-member user. It will also interface w/ the AuthPlugin (native app) and cookies (web) to
 * ensure they're in-sync.
 */
@Injectable({
    providedIn: 'root'
})
export class UserService {

    private static readonly RESTRICTED_USER_STATUSES = ['FAILED_INTERVIEW', 'NO_SHOW_INTERVIEW', 'PENDING', 'FIRED', 'DOUBLE_PROFILE', 'PAUSED_PROFILE'];

    /**
     * Most components will simply need to access this property. It's the observable
     * that represents the currently active user. Emits `null` to signal anonymous i.e.
     * nobody is logged-in.
     */
    user: Observable<User>;

    /**
     * Set by the AuthGuard in case we turn away a route b/c the user isn't logged-in.
     */
    redirectUrl?: string;

    private initialized: Promise<void>;
    private userBehaviorSubject: BehaviorSubject<User>;
    private branchData: Promise<any>;

    constructor(
        private navCtrl: NavController,
        private storage: Storage,
        private platform: Platform,
        private httpClient: HttpClient,
        private cookies: CookieService,
        private logger: Logger,
        private cmbUserApi: CmbUserApi,
        private cmbNoAuthApi: CmbNoAuthApi,
        private cmbPreferencesApi: CmbPreferencesApi,
        private zone: NgZone,
        @Inject(DOCUMENT) private doc: Document,
    ) {
        this.userBehaviorSubject = new BehaviorSubject<User>(null);
        this.user = this.userBehaviorSubject.asObservable().pipe(distinctUntilChanged<User>(isEqual));
        this.initialized = this.initialize();
        window['USER_SERVICE'] = this;
    }

    /**
     * UserService has some async initialization that must complete to load the initial user from storage/network.
     */
    ready(): Promise<void> {
        return this.initialized;
    }

    isCurrentUserRestricted(): boolean {
        return UserService.RESTRICTED_USER_STATUSES.includes(this.currentUser?.userInfo?.status);
    }

    isCurrentUserEligible(): boolean {
        return this.currentUser?.userInfo?.status === 'ELIGIBLE';
    }

    isTerminated(): boolean {
        return ['FIRED', 'FAILED_INTERVIEW'].includes(this.currentUser?.userInfo?.status);
    }

    isSuspended(): boolean {
        return ['SUSPENDED'].includes(this.currentUser?.userInfo?.status);
    }

    isCurrentUserPending(): boolean {
        return this.currentUser?.userInfo?.status === 'PENDING';
    }

    isCurrentUserPendingWorkAuth(): boolean {
        return ['NEEDS_I9', 'NEEDS_I9_AGENT', 'RUNNING_BACKGROUND_CHECK'].includes(this.currentUser?.userInfo?.status);
    }

    isCurrentUserEbI9Verified(): boolean {
        return !!this.currentUser?.userInfo?.isEbI9Verified;
    }

    async getCurrentExternalUserId(): Promise<string> {
        const storedExternalUserId = this.currentUser?.userInfo?.externalId;
        return !!storedExternalUserId ? storedExternalUserId : await this.getExternalUserIdFromToken();
    }

    private queryParamAuthToken() {
        const url = new URL(this.doc.URL);
        return url.searchParams.has('cognitoToken') ? url.searchParams.get('cognitoToken') :
            (url.searchParams.has('authToken') ? url.searchParams.get('authToken') : undefined);
    }

    private async initialize(): Promise<void> {
        // basic async setup + stuff that requires platform to be ready first
        await this.platform.ready();
        AuthPlugin?.addListener('nativeLogout', _ => {
            this.zone.run(async () => {
                await this.logout(false);
            });
        });

        // Check if we've a user stored
        const user = await this.getStoredUser();
        const qpAuth = this.queryParamAuthToken();
        if (user && this.tokensMatch(user) && !qpAuth && user?.userInfo?.personalInformation) {
            // only accept our user if the token matches what's stored in cookies (otherwise we hydrate from what's in cookies)
            this.userBehaviorSubject.next(user);
        } else {
            // clear our storage before doing anything; we always put everything in storage!
            await this.clearStorage();
            // We don't delete the bc.bearer since we're gonna sync with it.
            await this.handleMigration(qpAuth);
        }
        // update logger context on user change
        this.user.pipe(
            map(aUser => aUser?.userInfo?.externalId),
            distinctUntilChanged(isEqual)
        ).subscribe(userId => {
            this.logger.setContext({ userId });
        });
        // Setup intercom observer which lives for the lifetime of the app
        this.getBranchData();
        let booted = false;
        this.user.pipe(
            map(u => {
                if (u && u.userInfo) {
                    return {
                        email: u.userInfo.personalInformation?.emailAddress,
                        name: `${u.userInfo.personalInformation?.firstName} ${u.userInfo.personalInformation?.lastName}`,
                        phone_number: u.userInfo.personalInformation?.phone,
                        profile: `https://bluecrewjobs.com/app.html#!/worker/${u.userInfo.externalId}`,
                        payroll: `https://bluecrewjobs.com/app.html#!/pay/user/${u.userInfo.externalId}`,
                        region: u.userInfo.personalInformation?.address?.region,
                        status: u.userInfo.status,
                        type: UserType[u.userInfo.type],
                        user_id: u.userInfo.externalId
                    } as IntercomInfo;
                }
            }),
            distinctUntilChanged<IntercomInfo>(isEqual),
        ).subscribe({
            next: intercom => {
                if (intercom) {
                    if (!booted) {
                        booted = true;
                        this.logger.debug('Intercom.boot');
                        (this.doc.defaultView as any).Intercom('boot', {
                            app_id: environment.INTERCOM_ID,
                            hide_default_launcher: true,
                        });
                    }
                    this.logger.debug('Intercom.update ', intercom);
                    (this.doc.defaultView as any).Intercom('update', intercom);
                } else if (booted) {
                    booted = false;
                    this.logger.debug('Intercom.shutdown');
                    (this.doc.defaultView as any).Intercom('shutdown');
                }
            },
            error: e => {
                this.logger.error('user observable error', e);
            },
        });
    }

    private tokensMatch(u: User): boolean {
        return this.platform.is('hybrid') || u.apiV1Token === this.cookies.get(V1_TOKEN_NAME);
    }

    private getBranchData() {
        if (this.branchData || this.platform.is('hybrid')) return this.branchData;

        this.branchData = new Promise<void>((resolve) => {
            const timeoutTimer = this.doc.defaultView.setTimeout(() => {
                console.warn('Branch timed out');
                resolve();
            }, 4000);
            try {
                (this.doc.defaultView as any).branch.init(environment.BRANCH.key, {}, (err, res) => {
                    this.doc.defaultView.clearTimeout(timeoutTimer);
                    if (err) {
                        console.error(err);
                        resolve();
                        return;
                    }
                    resolve(res?.data_parsed);
                });
            } catch (err) {
                this.doc.defaultView.clearTimeout(timeoutTimer);
                console.error(err);
                resolve();
            }
        });

        return this.branchData;
    }

    private async getBranchParams(): Promise<Partial<UserSignupRequest>> {
        try {
            const branchData = await this.getBranchData();
            if (!branchData) return {};
            const out: Partial<UserSignupRequest> = { branchParams: {} };
            if (branchData['~channel']) {
                out.source = branchData['~channel'];
                out.branchParams.utmSource = branchData['~channel'];
            }
            if (branchData['~campaign']) {
                out.branchParams.utmMedium = branchData['~campaign'];
            }
            return out;
        } catch (err) {
            console.error(err);
            return {};
        }
    }

    private async handleMigration(qpAuthToken?: string) {
        let authToken: string;
        // check for the migration case where the token exists in either the AuthPlugin or
        // cookies, depending on the platform
        if (qpAuthToken) {
            authToken = qpAuthToken;
        } else if (this.platform.is('hybrid')) {
            authToken = (await AuthPlugin?.getAuthToken())?.authToken;
        } else {
            authToken = this.cookies.get(V1_TOKEN_NAME);
        }

        if (!authToken) {
            return;
        }

        try {
            const userInfo = await this.userDetails(authToken).toPromise();
            await this.updateAndStoreUser({
                apiV1Token: authToken,
                cmosToken: authToken,
                userInfo
            });
        } catch (err) {
            this.logger.error(`Error trying to get user details; logging out as a fallback`, err);
            // worst-case we force a logout, and they have to log back in.
            return await this.logout();
        }
    }

    /**
     * Synchronous access to the current user. Potentially incorrect before ready() resolves (will be null even
     * if there's an actively logged-in user).
     */
    get currentUser(): User {
        return this.userBehaviorSubject.value;
    }

    /**
     * Internal helper for the one-shot observables that most public methods return that shoudl wait for ready()
     * to resolve before running any method-specific business logic.
     */
    private whenReady<T>(f: () => Promise<T>): Observable<T> {
        return from(this.ready()).pipe(mergeMap(_ => f()));
    }

    /**
     * Signup a new crew-member. Internally will also make a call to login before resolving.
     * @param user
     */
    register(user: SignUpRequest): Observable<void> {
        const source = "NONE"; // Current form does not ask for source. Hard code to add NONE as source
        return this.whenReady(async () => {
            const userSignupRequest: UserSignupRequest = {
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                phoneNumber: user.phone_number,
                zipCode: user.zipcode,
                password: user.password,
                referrerCode: user.referrerCode,
                source: source,
                ...(await this.getBranchParams()),
                isCognitoVerified: true
            };
            if (!this.platform.is('hybrid') && this.getReferrer()) {
                userSignupRequest.referrer = this.getReferrer();
            }
            await this.cmbNoAuthApi.userSignup(userSignupRequest).toPromise();
            AnalyticsPlugin.logEvent({
                name: 'account_register',
                params: {
                    utmSource: `${userSignupRequest.branchParams?.utmSource}`,
                    utmMedium: `${userSignupRequest.branchParams?.utmMedium}`,
                    referrer: `${userSignupRequest.referrer}`,
                },
            });
            return await this.login({ username: user.email, password: user.password }).toPromise();
        });
    }

    async registerCognito(username: string, password: string, phoneNumber: string) {
        const { user } = await Auth.signUp({
            username,
            password,
            attributes: {
                phone_number: phoneNumber
            },
            autoSignIn: { // enables auto sign in after user is confirmed
              enabled: true,
            }
        });
        return user;
    }

    async getAuthToken(): Promise<string> {
        let token = this.userBehaviorSubject?.value?.apiV1Token;
        if (!token || !token.split('.')[1]) {
            await this.logout();
            return null;
        }
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        if ((Math.floor((new Date).getTime() / 1000)) >= expiry) {
            token = await this.getAuthTokenAndSetInStorage();
        }
        return token;
    }

    /**
     * Log in an existing crew-member. Internally syncs with the native platform's auth (or web cookies).
     * @param username
     * @param password
     */
    login(user: { username: string, password: string }): Observable<void> {
        Preconditions.notEmpty(user.username, 'Username must not be empty');
        Preconditions.notEmpty(user.password, 'Password must not be empty');

        return this.whenReady(async () => {
            await Auth.signIn(user.username, user.password);
            const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
            if (!this.platform.is('hybrid')) {
                const cookieOpts = () => {
                    const expires = new Date();
                    expires.setMonth(expires.getMonth() + 2);
                    return {
                        path: '/',
                        expires,
                        domain: environment.IS_LOCALHOST ? undefined : environment.V1_COOKIE_DOMAIN,
                    };
                };
                this.cookies.set(V1_TOKEN_NAME, jwtToken, cookieOpts());
            }
            await this.updateAndStoreUser({
                apiV1Token: jwtToken,
                cmosToken: jwtToken,
                userInfo: {} as any as UserInformation
            });
            const userInfo = await this.cmbUserApi.getUser().toPromise();
            await this.updateAndStoreUser({
                apiV1Token: jwtToken,
                cmosToken: jwtToken,
                userInfo: userInfo,
            });
            await AuthPlugin?.setAuthToken({ loginResponse: { authToken: jwtToken, userInfo } });
        });
    }

    async getCognitoUser(bypassCache: boolean = false): Promise<CognitoUser | any> {
        try {
            return await Auth.currentAuthenticatedUser({ bypassCache});
        } catch (error) { // Cognito throws an error if not authenticated
            return undefined;
        }
    }

    /**
     * Updates the user and returns the updated version. This is a one-shot observable, so components should still
     * subscribe to user. This is most useful for calling in a fire-and-forget manner to trigger an update.
     */
    getUpdatedUser(): Observable<User> {
        return this.whenReady(async () => {
            const jwtToken = await this.getAuthTokenAndSetInStorage();
            const response = await this.cmbUserApi.getUser().toPromise();
            await this.updateAndStoreUser({
                apiV1Token: jwtToken,
                cmosToken: jwtToken,
                userInfo: {
                    ...response,
                    personalInformation: {
                        ...response.personalInformation,
                        phone: this.normalizePhoneNumber(response.personalInformation.phone ?? '')
                    },
                }
            });
            return this.currentUser;
        });
    }

    /**
     * Gets a users details
     */
    getUserDetails(): Observable<UserInfo> {
        return this.cmbUserApi.getUser().pipe<UserInfo>(map((userInfo) => ({ userInfo } as UserInfo)));
    }

    /**
     * Logs out the current user and clears all storage. Will also signal to the native app host that we've logged-out.
     * @param callPlugin true if the AuthPlugin should be called; (set to false when we were triggered by the native-side)
     */
    async logout(callPlugin: boolean = true): Promise<void> {
        this.userBehaviorSubject.next(null);
        await Auth.signOut();
        if (!this.platform.is('hybrid')) {
            this.cookies.deleteAll('/');
            this.cookies.deleteAll('/', environment.V1_COOKIE_DOMAIN);
        }
        await Promise.all<any>([
            this.navCtrl.navigateRoot(AppRoutingUtil.HOME),
            this.clearStorage(),
            callPlugin ? AuthPlugin?.logout() : Promise.resolve(),
        ]);
    }

    private async getAuthTokenAndSetInStorage(): Promise<string> {
        let jwt;
        try {
            jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
        } catch (ex) {
            await this.logout();
            return null;
        }
        await this.updateAndStoreUser({
            apiV1Token: jwt,
            cmosToken: jwt,
            userInfo: {
                ...this.currentUser?.userInfo
            }
        });
        await AuthPlugin?.setAuthToken({ loginResponse: { authToken: jwt, userInfo: this.currentUser?.userInfo } });
        if (!this.platform.is('hybrid')) {
            const cookieOpts = () => {
                const expires = new Date();
                expires.setMonth(expires.getMonth() + 2);
                return {
                    path: '/',
                    expires,
                    domain: environment.IS_LOCALHOST ? undefined : environment.V1_COOKIE_DOMAIN,
                };
            };
            this.cookies.set(V1_TOKEN_NAME, jwt, cookieOpts());
        };
        return jwt;
    }

    // TODO: remove this code when web-angular stops calling CMA with authToken param.
    // Once all callers switch to Cognito tokens, we can just use the OAPI spec
    // to call CMOS and use the 'Authorization' header
    private userDetails(authToken: string): Observable<UserInformation> {
        let headers = {};
        const headerKey = isUsingCognitoToken(authToken) ? 'Authorization': 'v1-authorization';
        headers[headerKey] = isUsingCognitoToken(authToken) ? authToken : `Bearer ${authToken}`;
        return this.httpClient.get<UserInformation>(`${getCMOSUrlUsingAuthToken(authToken)}/api/v1/user`, {
            headers: headers
        });
    }

    // normalizes phones numbers in three formats (the first is the 'normal' form):
    // 1{ten digit number}
    // +1{ten digit number}
    // {ten digit number}
    // Has minimal checking; meant for API response handling **not** user validation.
    private normalizePhoneNumber(phone: string) {
        switch (phone.length) {
            case 10:
                return `1${phone}`;
            case 11:
                return phone;
            case 12:
                return phone.slice(1);
        }
        throw new Error('Unexpected phone length');
    }

    verifyCode(phoneNumber: string) {
        return this.whenReady(async () => {
            await this.httpClient.post(`${environment.API_V1_URL}/users/phoneverify`, { is_cognito_verified: true, phone_number: phoneNumber }).toPromise();
            await this.getUpdatedUser().toPromise();
        });

    }

    private async updateAndStoreUser(user: User): Promise<void> {
        const mergedUser = {
            ...await this.getStoredUser() || {},
            ...user,
        };
        this.userBehaviorSubject.next(mergedUser);
        await this.setStoredUser(mergedUser);
    }

    private async getStoredUser(): Promise<User> {
        // normalize falsy values to undefined
        return await this.storage.get(USER_KEY);
    }

    private async setStoredUser(u: User): Promise<void> {
        await this.storage.set(USER_KEY, u);
    }

    private getReferrer(): string {
        return this.cookies.get('originalReferrer') || this.doc.referrer || undefined;
    }

    updateWorkPreferences(preferredJobs: { [key: string]: string }) {
        const workPreferences: WorkPreferences = {
        };
        return this.cmbPreferencesApi.updateWorkPreferences(workPreferences);
    }

    updateWorkPreferencesSchedule(workPreferencesSchedule: WorkPreferencesSchedule) {
        return this.cmbPreferencesApi.updateWorkPreferences({ schedule: workPreferencesSchedule });
    }

    async setJobId(jobId: string) {
        if (!jobId) {
            await this.storage.remove(JOB_ID_KEY);
            return;
        }

        await this.storage.set(JOB_ID_KEY, { jobId });
    }

    async getJobId(): Promise<string> {
        return (await this.storage.get(JOB_ID_KEY))?.jobId;
    }

    async setJobIds(jobIds: string[]) {
        if (!jobIds) {
            await this.storage.remove(JOB_IDS_KEY);
            return;
        }

        await this.storage.set(JOB_IDS_KEY, { jobIds });
    }

    async getJobIds(): Promise<string[]> {
        return (await this.storage.get(JOB_IDS_KEY))?.jobIds;
    }

    private async clearStorage() {
        const jobId = await this.getJobId();
        await this.storage.clear();
        if (jobId) {
            await this.setJobId(jobId);
        }
    }

    async getLatestLegalAcceptance(legalAcceptanceId: LegalAcceptanceId): Promise<Date> {
        const acceptance = await this.cmbUserApi.getLatestLegalAcceptance(legalAcceptanceId).toPromise();
        return acceptance ? new Date(acceptance) : null;
    }

    async addLegalAcceptance(legalAcceptanceId: LegalAcceptanceId): Promise<void> {
        await this.cmbUserApi.addLegalAcceptance(legalAcceptanceId).toPromise();
    }

    async storePushToken(userPushToken: UserPushToken): Promise<void> {
        await this.cmbUserApi.storePushToken(userPushToken).toPromise();
    }

    updateUserProfile(userProfile: UserProfile): Observable<void> {
        return this.whenReady(async () => {
            await this.cmbUserApi.updateUser(userProfile).toPromise();
        });
    }

    private async getExternalUserIdFromToken(): Promise<string> {
        const cognitoUser = await this.getCognitoUser() as CognitoUser;
        const externalUserId = cognitoUser?.getSignInUserSession()?.getIdToken()?.decodePayload()['custom:external_user_id'];
        console.warn(`Returning external user ID [${externalUserId}]`);
        return externalUserId;
    }

    async createBasicInformation(basicInformationRequest: BasicInformationRequest): Promise<void> {
      await this.cmbUserApi.createBasicInformation(basicInformationRequest).toPromise();
    }
}
