import { Injectable } from '@angular/core';
import { ConsoleLogger } from './console-logger.service';
import { Logger, LoggerContext } from './logger.service';
import { SentryRemoteLogger } from './sentry-remote-logger.service';

/**
 * Composite logger implementation that delegates logging call to all configured loggers
 */
@Injectable({
  providedIn: 'root'
})
export class CompositeLogger extends Logger {

  private concreteLoggers: Logger[] = [];

  constructor(private consoleLogger: ConsoleLogger,
              private sentryRemoteLogger: SentryRemoteLogger) {
    super();
    this.concreteLoggers.push(consoleLogger);
    this.concreteLoggers.push(sentryRemoteLogger);
  }

  setContext(context: LoggerContext) {
    this.concreteLoggers.forEach(logger => logger.setContext(context));
  }

  debug(...message: any) {
    this.concreteLoggers.forEach(logger => logger.debug(message));
  }

  log(...message: any) {
    this.concreteLoggers.forEach(logger => logger.log(message));
  }

  warn(...message: any) {
    this.concreteLoggers.forEach(logger => logger.warn(message));
  }

  error(...message: any) {
    this.concreteLoggers.forEach(logger => logger.error(message));
  }
}
