import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IntercomService } from '../services/intercom.service';

@Injectable({
  providedIn: 'root'
})
export class ContactSupportGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly intercomService: IntercomService,
) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.intercomService.show();
      return this.router.parseUrl('');
  }
  
}
