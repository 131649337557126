import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { AppRoutingUtil } from '../util/app-routing-util';

@Injectable({
  providedIn: 'root',
})
export class UserTerminatedGuard implements CanActivateChild {
  constructor(private readonly userService: UserService, private readonly router: Router) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if ((this.userService.isTerminated() || this.userService.isSuspended()) && this.notValidPath(state.url)) {
      return this.router.parseUrl(AppRoutingUtil.USER_TERMINATED);
    }

    return true;
  }

  notValidPath(url: string) {
    return ![AppRoutingUtil.USER_TERMINATED, AppRoutingUtil.LEGAL, AppRoutingUtil.TAKEOVER_SCREEN].includes(url);
  }
}
