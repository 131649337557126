import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Amplify } from 'aws-amplify';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

defineCustomElements(window);

Amplify.configure({
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  region: environment.COGNITO_CONFIG.aws_cognito_region,
  userPoolId: environment.COGNITO_CONFIG.aws_user_pools_id,
  userPoolWebClientId: environment.COGNITO_CONFIG.aws_user_pools_web_client_id
});
