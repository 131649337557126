export class AppRoutingUtil {
  static SLASH = '/';

  // params
  static JOB_ID_PARAM = 'jobId';
  static POSITION_ID_PARAM = 'positionId';
  static SHIFT_INDEX_PARAM = 'shiftIndex';

  // routes
  // all route prefixes MUST start with a leading forward slash '/'
  static JOB_ROUTE_PREFIX = '/job';
  static JOB_ROUTE_SUFFIX = `/:${AppRoutingUtil.JOB_ID_PARAM}`;
  static POSITION_JOB_ROUTE_PREFIX = '/position/job';
  static POSITION_JOB_ROUTE_SUFFIX = `/:${AppRoutingUtil.JOB_ID_PARAM}`;
  static POSITION_ROUTE_PREFIX = '/position';
  static POSITION_ROUTE_SUFFIX = `/:${AppRoutingUtil.POSITION_ID_PARAM}`;
  static POSITION_MULTI_SELECT = `/multi-select`;
  static SHIFT_DETAIL_PREFIX = '/shift-details';
  static SHIFT_DETAIL_INTER = `/:${AppRoutingUtil.JOB_ID_PARAM}`;
  static SHIFT_DETAIL_SUFFIX = `/:${AppRoutingUtil.SHIFT_INDEX_PARAM}`;

  static CANCEL_JOB_PREFIX = '/cancel-job';
  static CANCEL_JOB_SUFFIX = `/:${AppRoutingUtil.JOB_ID_PARAM}`;

  static CANCEL_SHIFT_PREFIX = '/cancel-shift';
  static CANCEL_SHIFT_INTER = `/:${AppRoutingUtil.JOB_ID_PARAM}`;
  // TODO: Needs to be refactor to shiftIndex - There's no concept of ShiftId in BC.
  static CANCEL_SHIFT_SUFFIX = `/:shiftId`;

  // Browse Jobs Routes
  static BROWSE_JOBS: string = '/browse-jobs';
  static BROWSE_JOBS_MAP: string = '/browse-jobs-map';

  static BROWSE_JOBS_JOB_PREFERENCES: string = `${AppRoutingUtil.BROWSE_JOBS}/job-preferences`;
  static BROWSE_JOBS_MAP_JOB_PREFERENCES: string = `${AppRoutingUtil.BROWSE_JOBS_MAP}/job-preferences`;

  // User Info / Account Route
  static ACCOUNT: string = '/account';
  static ACKNOWLEDGE_STRIKES: string = '/acknowledge-strikes';
  static STRIKE_POLICIES: string = '/strike-policies';
  static USER_INFO: string = '/user-info';

  static USER_TERMINATED: string = '/terminated';

  static ACCOUNT_FORGOT_PASSWORD = `${AppRoutingUtil.ACCOUNT}/forgot-password`;
  static ACCOUNT_FORGOT_PASSWORD_SUBMIT = `${AppRoutingUtil.ACCOUNT}/forgot-password-submit`;
  static ACCOUNT_REGISTER = `${AppRoutingUtil.ACCOUNT}/register`;
  static ACCOUNT_RESEND_CODE = `${AppRoutingUtil.ACCOUNT}/resend-code`;
  static ACCOUNT_SIGNIN = `${AppRoutingUtil.ACCOUNT}/signin`;
  static ACCOUNT_VERIFICATION_CODE = `${AppRoutingUtil.ACCOUNT}/verification-code`;
  static USER_INFO_EDIT_PROFILE: string = `${AppRoutingUtil.USER_INFO}/edit-profile`;
  static USER_INFO_EDIT_PROFILE_INTERCOM_SUCCESS: string = `${AppRoutingUtil.USER_INFO_EDIT_PROFILE}/intercom-success`;
  static USER_INFO_PROFILE: string = `${AppRoutingUtil.USER_INFO}/profile`;

  // Nav Menu Routes
  static DASHBOARD = '/dashboard';
  static LEGAL = '/legal';
  static SCHEDULE = '/schedule';

  // Job / Position Deep-links
  static APPLICATION_HOME = '/application-home';
  static APPLICATION_HOME_POST_SUBMIT = `${AppRoutingUtil.APPLICATION_HOME}/post-submit`;

  // Takeover screen routes
  static POSITION_UPDATES = '/position-updates';
  static TAKEOVER_SCREEN = '/needs-action';
  static TERMS_OF_USE = '/terms-of-use';
  static UPDATE_APP = '/update-app';

  // Misc. Routes
  static APP_STORE_LINKS = '/app-store-links';
  static HOME = '/home';
  static OFFLINE = '/offline';

  /**
   * Removes the leading forward slash from a provided route.
   * app-routing-module.ts assumes all paths start from root, so no paths
   * can include a leading forward slash '/'
   *
   * @param route full route
   * @returns full route with the assumption it starts from root, '/'
   */
  static getRouteForRoot(route: string) {
    return route.replace(AppRoutingUtil.SLASH, '');
  }

  static getJobRoute(jobId: string) {
    return AppRoutingUtil.JOB_ROUTE_PREFIX + AppRoutingUtil.SLASH + jobId;
  }

  static getPositionJobRoute(jobId: string) {
    return AppRoutingUtil.POSITION_JOB_ROUTE_PREFIX + AppRoutingUtil.SLASH + jobId;
  }

  static getPositionRoute(positionId: string) {
    return AppRoutingUtil.POSITION_ROUTE_PREFIX + AppRoutingUtil.SLASH + positionId;
  }

  static getPositionMultiSelectRoute(positionId: string) {
    return AppRoutingUtil.POSITION_ROUTE_PREFIX + AppRoutingUtil.SLASH + positionId + AppRoutingUtil.POSITION_MULTI_SELECT;
  }

  static getShiftDetailsRoute(jobId: string, shiftIndex: string) {
    return AppRoutingUtil.SHIFT_DETAIL_PREFIX + AppRoutingUtil.SLASH + jobId + AppRoutingUtil.SLASH + shiftIndex;
  }

  static getCancelJobRoute(jobId: string) {
    return AppRoutingUtil.CANCEL_JOB_PREFIX + AppRoutingUtil.SLASH + jobId;
  }

  static getCancelShiftRoute(jobId: string, shiftIndex: number) {
    return AppRoutingUtil.CANCEL_SHIFT_PREFIX + AppRoutingUtil.SLASH + jobId + AppRoutingUtil.SLASH + shiftIndex;
  }

  static getProfilePageRoute() {
    return AppRoutingUtil.USER_INFO_PROFILE;
  }

}
