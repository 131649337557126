/**
 * TODO Doc Updates
 *
 * Helper utility for various input parameter checking
 */
export class Preconditions {

    protected constructor() {}

    /**
     * Checks whether a provided input string is null|undefined|empty
     * and throws the provided message wrapped in an error if so.
     * Takes no action if the provided input is not empty
     * @param input the string to check for emptiness
     * @param message the message to throw when the provided string is empty
     */
    static notEmpty(input: string, message: string) {
        if (!input) {
            throw new Error(message);
        }
    }
}
