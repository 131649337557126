import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { MenuController, Platform, NavController } from '@ionic/angular';
import { UserService } from '../services/user.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppRoutingUtil } from '../util/app-routing-util';
import { IntercomService } from '../services/intercom.service';
import { NavigationPlugin } from '@plugins/navigation/navigation-plugin';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit, OnDestroy {
  menuItems: MenuItem[] = [];

  swipeEnabled = false;

  private subs: Subscription;

  constructor(private menu: MenuController,
              private userService: UserService,
              private platform: Platform,
              private navCtrl: NavController,
              private readonly intercomService: IntercomService) {
  }

  toggleSwipe(enabled: boolean) {
    this.swipeEnabled = enabled;
  }

  private setUpItems() {
    this.menuItems = this.baseItems().filter(item => {
      if (this.platform.is('hybrid')) {
        return !item.hideNative;
      } else {
        return !item.hideWeb;
      }
    });
  }

  async ngOnInit() {
    await this.platform.ready();
    await this.userService.ready();
    this.subs = new Subscription();
    this.subs.add(this.userService.user.pipe(distinctUntilChanged((first, second) => {
      return first?.userInfo?.status === second?.userInfo?.status;
    })).subscribe({
      next: () => {
        this.setUpItems();
      },
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getAsset(name: string) {
    return `/assets/icons/${name}.svg`;
  }

  private baseItems(): MenuItem[] {
    if (this.userService.isTerminated()) {
      return [
        {
          id: 'Log out',
          icon: 'log-out',
          logOut: true,
        }
      ]
    }

    if (this.userService.isSuspended()) {
      return [
        {
          id: 'Dashboard',
          icon: 'dashboard-home',
        },
        {
          id: 'Help',
          icon: 'help',
          openIntercom: true,
        }, {
          id: 'Useful Information',
          icon: 'legal',
        }, {
          id: 'Log out',
          icon: 'log-out',
          logOut: true,
        }
      ]
    }


    return [{
      id: 'Dashboard',
      icon: 'dashboard-home',
    }, {
      id: 'Find Work',
      icon: 'find-work',
      hideWeb: true,
    }, {
      id: 'Find Work',
      icon: 'find-work',
      hideNative: true,
    }, {
      id: 'Schedule',
      icon: 'schedule',
      hideWeb: false,
    }, {
      id: 'Account',
      icon: 'account',
    }, {
      id: 'Payroll',
      name: 'Earnings',
      icon: 'earnings',
      browserUrl: () => `${environment.WEB_V1_URL}/app.html#!/pay/user?cognitoToken=${this.userService.currentUser.apiV1Token}`,
    }, {
      id: 'Refer a friend',
      icon: 'refer-friend',
      hideWeb: true,
    }, {
      id: 'Help',
      icon: 'help',
      openIntercom: true,
    }, {
      id: 'Useful Information',
      icon: 'legal',
    }, {
      id: 'Log out',
      icon: 'log-out',
      logOut: true,
    }];
  }

  async onItemClicked(item: MenuItem) {
    this.menu.close();

    if (item.openIntercom) {
      this.intercomService.show();
    } else if (item.logOut) {
      this.userService.logout();
    } else if (item.id === 'Dashboard') {
      this.navCtrl.navigateBack(AppRoutingUtil.HOME, { state: { resetOfflineMode: true } });
    } else if (item.id === 'Find Work') {
      this.navCtrl.navigateForward(AppRoutingUtil.BROWSE_JOBS, { state: { resetOfflineMode: true } });
    } else if (item.id === 'Useful Information') {
      this.navCtrl.navigateForward(AppRoutingUtil.LEGAL, { state: { resetOfflineMode: true } });
    } else if (item.id === 'Account') {
      this.navCtrl.navigateForward(AppRoutingUtil.USER_INFO);
    } else if (item.id === 'Schedule') {
      this.navCtrl.navigateForward(AppRoutingUtil.SCHEDULE, { state: { resetOfflineMode: true } });
    } else {
      this.navigateLegacy(item);
    }
  }

  navigateLegacy(item: MenuItem) {
    if (item.browserUrl) {
      Browser.open({
        url: item.browserUrl(),
      });
    } else if (this.platform.is('hybrid')) {
      NavigationPlugin?.goToNativeScreen({
        path: item.id,
      });
    }
  }

}

export interface MenuItem {
  /**
   * Logical identifier for the item; also the name if `name` isn't defined.
   */
  id: string;

  /**
   * ion icon to use (might change)
   */
  icon: string;

  /**
   * name to use in the list if `id` isn't it.
   */
  name?: string;

  /**
   * Navigation opens a new browser tab regardless of platform
   */
  browserUrl?: () => string;

  /**
   * If we should open intercom client
   */
  openIntercom?: boolean;

  /**
   * Hide this item if running in native app
   */
  hideNative?: boolean;

  /**
   * Hide this item if running as a website
   */
  hideWeb?: boolean;

  /**
   * Do the logout on web.
   */
  logOut?: boolean;

  // TODO for internal navigation we'd use a lambda calling navCtrl
}
