import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { Platform } from '@ionic/angular';
import { AnalyticsService as CmbAnalyticsApi, SegmentEventRequest } from '@bluecrew/crew-member-backend-angular-client';
import { getCommonProps } from '../util/analytics-util';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class SegmentCMOSService {

  constructor(private readonly cmbAnalyticsApi: CmbAnalyticsApi,
              private readonly device: Device, 
              private readonly platform: Platform,
              private readonly userService: UserService) { }

  async addEvent(eventName: string, props?: any) {
    await this.userService.ready();
    let commonProps = getCommonProps(this.platform, this.device);
    const req: SegmentEventRequest = {
      eventName: eventName,
      props: {
        ...commonProps,
        ...props
      }
    };
    if (this.userService.currentUser) {
      this.cmbAnalyticsApi.addSegmentEvent(req).toPromise();
    } else {
      this.cmbAnalyticsApi.addSegmentEventAnonymous(req).toPromise();
    }
  }
}
