import { Injectable } from '@angular/core';
import { PubSubService } from './pub-sub.service';
import { environment } from 'src/environments/environment';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { OfflineTimestampSyncer } from './offline-timestamp-syncer.service';
import { AppRoutingUtil } from '../util/app-routing-util';

@Injectable({
  providedIn: 'root'
})
export class OfflineNetworkService {

  private intervalId: ReturnType<typeof setInterval>;
  private redirectUrl: string;

  constructor(
    private pubsub: PubSubService,
    private navCtrl: NavController,
    private offlineSyncTimestamp: OfflineTimestampSyncer,
    private router: Router) {
    this.pubsub.offline.subscribe({
      next: () => {
        if (!this.redirectUrl) {
          this.redirectUrl = (this.router.url !== AppRoutingUtil.OFFLINE ? this.router.url : '/') || '/';
          this.navCtrl.navigateForward(AppRoutingUtil.OFFLINE, {
            state: { fromOfflineNetworkService: true },  // set a flag to know it's a programmatic navigation we intended
            skipLocationChange: true,
          });
          this.intervalId = setInterval(() => this.foregroundEvent(), 15000);
        }
      },
    });
    this.pubsub.online.subscribe({
      next: () => this.onlineOrForegroundEvent(),  // could also just use 'goBack()' if we trust we're def online
    });
    this.pubsub.foreground.subscribe({
      next: () => this.foregroundEvent(),
    });
  }

  allowNavigation(): boolean {
    return !this.redirectUrl;
  }

  reset(): void {
    if (this.redirectUrl) {
      this.redirectUrl = undefined;
      clearInterval(this.intervalId);
    }
  }

  private async onlineEvent() {
    await this.offlineSyncTimestamp.commit();
  }

  private async foregroundEvent() {
    // to prevent unnecessary health-checks, we make sure there's a redirectUrl set i.e. we're actively checking if network is back on
    if (this.redirectUrl && await this.isOnline()) {
      this.goBack();
    }
  }

  private async onlineOrForegroundEvent() {
    await this.onlineEvent();
    await this.foregroundEvent();
  }

  private goBack() {
    if (this.redirectUrl) {
      const redirect = this.redirectUrl;
      this.reset();
      this.navCtrl.navigateBack(redirect, { replaceUrl: true });
    }
  }

  private async isOnline() {
    try {
      // We're using raw 'fetch' here to run outside of any http interceptors or whatever we might have.
      await fetch(`${environment.CMOS_ORIGIN}/health`);
      return true;
    } catch (error) {
      return false;
    }
  }
}
