import { environment } from "src/environments/environment";

// Cognito tokens are JWT, which have a combination of strings concatenated with '.'
export const isUsingCognitoToken = (authToken: String): boolean => {
    return authToken.includes('.');
}

export const getApiV1UrlUsingAuthToken = (authToken: String): String => {
    return isUsingCognitoToken(authToken) ? environment.API_V1_URL : environment.API_V1_LEGACY_URL;
}

export const getCMOSUrlUsingAuthToken = (authToken: String): String => {
    return isUsingCognitoToken(authToken) ? environment.CMOS_ORIGIN : environment.CMOS_LEGACY_URL;
}
