import { ErrorHandler, Injectable } from '@angular/core';
import * as SentryAngular from "@sentry/angular-ivy";
import * as Sentry from "@sentry/capacitor";
import { environment } from 'src/environments/environment';
import { AnalyticsPlugin } from '@plugins/analytics/analytics-plugin';

/**
 * SentryErrorHandler to wrap logic in try-catch to prevent any impact due to Sentry related issues
 */
@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  constructor() {
    try {
      Sentry.init(
        {
          dsn: environment.SENTRY.dsn,
          release: environment.APP_VERSION,
          enableOutOfMemoryTracking: true,
          environment: environment.production ? "production" : "staging",
          replaysOnErrorSampleRate: 1.0,
          replaysSessionSampleRate: 0.1,
          integrations: [
            new SentryAngular.Replay({
                maskAllText: false,
                blockAllMedia: true
              })
          ],
          // ignoring some common user errors, unrelated to the app working as intended
          ignoreErrors: [/UserNotFoundException/, /NotAuthorizedException/, /UsernameExistsException/]
        },
        SentryAngular.init
      );
    } catch (err) {
      AnalyticsPlugin.logEvent({
        name: 'sentry_init_failure',
        params: {
          message: err?.message?.slice(0, 40) || '<none>',
        },
      });
    }
  }

  handleError(error) {
    try {
      Sentry.captureException(error);
    } catch (err) {
      AnalyticsPlugin.logEvent({
        name: 'sentry_capture_exception_failure',
        params: {
          message: err?.message?.slice(0, 40) || '<none>',
        },
      });
    }
  }
}
