import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AppRoutingUtil } from '../util/app-routing-util';
import { BROWSE_JOBS_LIST_STORAGE_VALUE, BROWSE_JOBS_STORAGE_KEY } from '../util/storage-util';

@Injectable({
  providedIn: 'root'
})
export class BrowseJobsGuard implements CanActivate {
  
  private readonly allowedPaths = [ AppRoutingUtil.BROWSE_JOBS, AppRoutingUtil.BROWSE_JOBS_MAP ];

  constructor(
    private router: Router,
    private storage: Storage,) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    const bypass = this.router.getCurrentNavigation().extras?.state?.bypassBrowseJobsGuard;
    if (!bypass && this.allowedPaths.includes(state.url)) {
      const browseJobsPreference = await this.storage.get(BROWSE_JOBS_STORAGE_KEY);
      let path = AppRoutingUtil.BROWSE_JOBS_MAP;
      if (browseJobsPreference === BROWSE_JOBS_LIST_STORAGE_VALUE) {
        path = AppRoutingUtil.BROWSE_JOBS;
      }
      if (path !== state.url) {
        return this.router.parseUrl(path);
      }
    }
    return true;
  }
}
