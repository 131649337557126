import { Injectable, } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavController, IonRouterOutlet } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UrlHistoryService {

  history: string[] = [];

  constructor(private router: Router, private navCtrl: NavController) {
    this.router.events
      ?.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.history.unshift(event.urlAfterRedirects);
        if (this.history.length > 100) {
          // only save the last 100 navigations
          this.history.length = 100;
        }
      });
  }

  /**
   * BEWARE MAKING CHANGES TO THIS FUNCTION! In ionic pop() and back() are very different things. Ionic has a concept
   * of a view-stack, and that's what pop will act upon (ie if you have a home -> job-detail -> review-page you can pop the review-page off
   * job-detail and pop job-detail off home). However, back uses the browser history, which doesn't understand the stack, so if you're going
   * b/w two pages you can enter an infinite loop (job-detail -> review-page example: going 'back' from review takes you to job-detail; going 'back'
   * from job-detail takes you back to review!).
   * @param defaultUrl 
   */
  async goBackWithDefault(defaultUrl = '/') {
    const didGoBack = await this.pop();
    if (!didGoBack) {
      await this.navCtrl.navigateBack(defaultUrl);
    }
  }

  /**
   * DANGER! This is risky business...essentially relying on internal Ionic details in order to ultimately do
   * the right thing. This is essentially Ionic's implementation, except it returns whether or not there was
   * something to pop. This allows us to use the default navigation once we've exhausted the stack.
   * https://github.com/ionic-team/ionic-framework/blob/3821c0463ae9a02e67c835a221c4ea01fab306d1/angular/src/providers/nav-controller.ts#L128-L144
   */
  private async pop(): Promise<boolean> {
    let outlet: IonRouterOutlet = this.navCtrl['topOutlet'];
    while (outlet) {
      if (await outlet.pop()) {
        // Returns true if we were able to "go back"
        return true;
      } else {
        outlet = outlet.parentOutlet;
      }
    }
    // Return false in case there's nothing to pop()
    return false;
  }
}
