import { APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { Device } from '@ionic-native/device/ngx';

import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SentryErrorHandler } from './interceptors/sentry-error-handler';
import { CompositeLogger } from './util/logger/composite-logger.service';
import { Logger } from './util/logger/logger.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ApiModule as CmosApiModule, Configuration as CmosApiConfig } from '@bluecrew/crew-member-backend-angular-client';
import { CmosInterceptor } from './interceptors/cmos.interceptor';
import { ApiV1Interceptor } from './interceptors/api-v1.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { RetriesInterceptor } from './interceptors/retries.interceptor';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { PipeModule } from './pipes/pipe.module';
import { environment } from '../environments/environment';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

@NgModule({
  declarations: [AppComponent, NavMenuComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
      rippleEffect: false
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CmosApiModule.forRoot(() => new CmosApiConfig({
      basePath: `${environment.CMOS_ORIGIN}/api/v1`,
    })),
    NgCircleProgressModule.forRoot({
      // These defaults are set for the application progress wheel. If reusing, then specify your
      // own values on the element itself; DO NOT MODIFY THIS UNLESS YOU'RE MODIFYING application-progress.component
      radius: 22,
      outerStrokeWidth: 6,
      innerStrokeWidth: 6,
      innerStrokeColor: "#F2F3F7",
      outerStrokeColor: "#3246F0",
      animationDuration: 50,
      showInnerStroke: true,
      showTitle: false,
      showSubtitle: false,
      showUnits: false,
      space: -6,
      showZeroOuterStroke: false,
    }),
    PipeModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: CmosInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiV1Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetriesInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: Logger, useClass: CompositeLogger },
    DatePicker,
    OpenNativeSettings,
    Geolocation,
    Device,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
