import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Logger } from '../util/logger/logger.service';
import { UserService } from '../services/user.service';
import { Observable, from } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { MetricsInterceptorService } from '../services/metrics-interceptor.service';
import { Platform } from '@ionic/angular';
import { getPlatform } from '../util/analytics-util';
import { mergeMap } from 'rxjs/operators';

/**
 * Interceptor to intercept outgoing Http Requests and detect if they are API-V1 requests
 * and optionally add additional information to the request before sending out
 */
@Injectable()
export class ApiV1Interceptor implements HttpInterceptor {
    constructor(private logger: Logger,
                private metrics: MetricsInterceptorService,
                private userService: UserService,
                private platform: Platform) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const isApiV1Route = request.url.startsWith(environment.API_V1_URL) || request.url.startsWith(environment.API_V1_LEGACY_URL);
        if (!isApiV1Route) return next.handle(request);
        let headers = {
            'x-bluecrew-version': `${environment.APP_VERSION}`,
            'x-bluecrew-platform': getPlatform(this.platform)
        }

        const user = this.userService.currentUser;

        if (user && !request.headers.has('Authorization')) {
            if (request.url.startsWith('http://localhost')) {
                headers['X-API-KEY'] = `${environment.API_V1_LOCAL_API_KEY}`;
                headers['X-USER-ID']= `${user.userInfo.externalId}`;
            } else {
                return from(this.userService.getAuthToken()).pipe(
                    mergeMap(token => {
                        headers['Authorization'] = `${request.url.startsWith(environment.API_V1_LEGACY_URL) ? 'Bearer ' : ''}${token}`
                        request = request.clone({
                            setHeaders: headers
                        });
                        return this.metrics.instrumentHttpRequest('apiv1', request, next);
                    })
                );
            }
        } else if (request.url.startsWith(environment.API_V1_LEGACY_URL) && !request.headers.get('Authorization').startsWith('Bearer')) {
            // Append the 'Bearer ' prefix to legacy api-v1 gateway calls since it uses Bearer token
            headers['Authorization'] = 'Bearer ' + request.headers.get('Authorization');
        }
        
        request = request.clone({
            setHeaders: headers
        });
        
        return this.metrics.instrumentHttpRequest('apiv1', request, next);
    }
}
