export const environment = {
  production: true,
  API_V1_LEGACY_URL: 'https://api.bluecrewjobs.com/v1',
  API_V1_URL: 'https://apiv1.prd.us-west-2.gateway.bluecrewjobs.com/v1',
  API_V1_LOCAL_API_KEY: '',
  WEB_V1_URL: 'https://app.bluecrewjobs.com',
  CREW_URL: 'https://cm.bluecrewjobs.com',
  WEB_CREW_URL: 'https://web-cm.bluecrewjobs.com',
  HELLOSIGN_URL: 'https://hellosign.bluecrewjobs.com',
  V1_COOKIE_DOMAIN: '.bluecrewjobs.com',
  IS_LOCALHOST: false,
  INTERCOM_ID: 'ni1nczdn',
  GOOGLE_MAPS: 'AIzaSyDwhnn-mRU4kFKers9tv6zTKg6uq-5A9Ug',
  CMOS_LEGACY_URL: 'https://jd2kwbrai1.execute-api.us-west-2.amazonaws.com',
  CMOS_ORIGIN: 'https://crewmemberbackend.prd.us-west-2.gateway.bluecrewjobs.com',
  CMOS_LOCAL_API_KEY: '',
  APP_VERSION: '7.576.0',
  FIREBASE_WEB: {
    apiKey: "AIzaSyAVgtX2eooe0gZ3KPOTtpPARgb0JZj5ixc",
    authDomain: "bluecrew-bd5f1.firebaseapp.com",
    databaseURL: "https://bluecrew-bd5f1.firebaseio.com",
    projectId: "bluecrew-bd5f1",
    storageBucket: "bluecrew-bd5f1.appspot.com",
    messagingSenderId: "597130877537",
    appId: "1:597130877537:web:a3c99262dd5870a7196820",
    measurementId: "G-BZLBEXX04E"
  },
  BRANCH: {
    key: 'key_live_daaWYcEPKn47uiJaUKIsSjdhqqlhx7hs',
  },
  SPLIT: {
    apiKey: "3e2kk2g1d9vivedkvt1n3o2i9h2tpgtfq93n"
  },
  SENTRY: {
    dsn: "https://e738c47741604905bd7d28cb3d74e313@o1235227.ingest.sentry.io/6385138"
  },
  INTERCOM_ARTICLE_IDS: {
    POSITION_UPDATES: 6330982
  },
  ONETRUST: {
    PREFERENCE_CENTER_LINK: 'https://privacyportal.onetrust.com/ui/#/preferences/multipage/token/14301355-c016-41e8-9b08-086a267f6817/'
  },
  // TODO: update to prd user pool
  COGNITO_CONFIG: {
    aws_project_region: 'us-west-2',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_Gqp0ANH55',
    aws_user_pools_web_client_id: '2chrbcv2ta08khrg0o4f1u68r0'
  }
};
