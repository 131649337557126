import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { SegmentCMOSService } from 'src/app/services/segment-cmos.service';
import { AppRoutingUtil } from '../util/app-routing-util';

@Component({
  selector: 'app-app-store-links',
  templateUrl: './app-store-links.page.html',
  styleUrls: ['./app-store-links.page.scss'],
})
export class AppStoreLinksPage implements OnInit {

  showAsFullPage = false;

  constructor(private modalCtrl: ModalController,
              private activatedRoute: ActivatedRoute,
              private navCtrl: NavController,
              private segmentCMOS: SegmentCMOSService) {
    this.activatedRoute.queryParams.subscribe((result) => {
      this.showAsFullPage = result['showAsFullPage'];
    })
  }

  ngOnInit() { }

  async onDismiss() {
    if (this.showAsFullPage) {
      this.segmentCMOS.addEvent('dismissPage_clickedFromAppStoreLinksFullPage');
      await this.navCtrl.navigateForward(AppRoutingUtil.DASHBOARD)
    } else {
      this.modalCtrl.dismiss();
    }
  }
}
