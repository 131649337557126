import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Logger, LoggerContext } from './logger.service';
import { AnalyticsPlugin } from '@plugins/analytics/analytics-plugin';

@Injectable({
  providedIn: 'root'
})
export class SentryRemoteLogger extends Logger {

  constructor() {
    super();
  }

  setContext(context: LoggerContext) {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: context?.userId || 'anonymous'
      });
    });
  }

  debug(...message: any) {
    // Do nothing
  }

  log(...message: any) {
    // Do nothing
  }

  warn(...message: any) {
    // Do nothing
  }

  error(...messages: any) {
    try {
      messages[0].forEach(element => {
        if (element instanceof Error) {
          Sentry.captureException(element);
        } else if (element instanceof HttpErrorResponse) {
          this.captureHttpErrorResponse(element);
        }
      });
    } catch (err) {
      AnalyticsPlugin.logEvent({
        name: 'sentry_capture_exception_failure',
        params: {
          message: err?.message?.slice(0, 40) || '<none>',
        },
      });
    }
  }

  private captureHttpErrorResponse(errorResponse: HttpErrorResponse) {
    const level = Math.floor(errorResponse.status / 100);
    // remove client exceptions
    if (level !== 4) {
      Sentry.configureScope(scope => {
        scope.setLevel('error');
        scope.setFingerprint([errorResponse.url, errorResponse.status, errorResponse.error.reason]);
        scope.setTag('url', errorResponse.url);
        scope.setTag('status', errorResponse.status);
        scope.setTag('reason', errorResponse.error.reason);
        scope.setExtra('response', errorResponse);
      });
      Sentry.captureMessage(errorResponse.message);
    }
  }
}
