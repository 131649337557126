import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { identity } from 'lodash-es';
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';

/**
 * Use sparingly...provides "pub sub"-like interactions to decouple requests from reactions.
 * Publishers should have a dedicated method e.g. request<Blank> and subscribers learn about
 * updates by subscribing to the relevant Observable e.g. <Blank>. All observables are 'hot'
 * (as in a traditional pubsub scheme).
 */
@Injectable({
  providedIn: 'root'
})
export class PubSubService {

  foreground: Observable<unknown>;
  private isInForegroundSubject: Subject<boolean>;

  offline: Observable<unknown>;
  private offlineSubject: Subject<unknown>;

  online: Observable<unknown>;
  private onlineSubject: Subject<unknown>;

  constructor(private zone: NgZone) {
    this.isInForegroundSubject = new Subject();
    this.foreground = this.isInForegroundSubject.asObservable().pipe(debounceTime(300), filter(identity));
    App?.addListener('appStateChange', appState => {
      this.zone.run(() => {
        this.isInForegroundSubject.next(appState.isActive);
      });
    });

    this.offlineSubject = new Subject();
    this.offline = this.offlineSubject.asObservable();

    this.onlineSubject = new Subject();
    this.online = this.onlineSubject.asObservable();

    Network?.addListener('networkStatusChange', netStatus => {
      this.zone.run(() => {
        netStatus.connected ? this.onlineSubject.next() : this.offlineSubject.next();
      });
    });
  }

  notifyOffline() {
    this.offlineSubject.next();
  }

  notifyOnline() {
    this.onlineSubject.next();
  }

  notifyForeground() {
    this.isInForegroundSubject.next(true);
  }
}
