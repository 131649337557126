import { Pipe, PipeTransform } from '@angular/core';
import { jobTypeToName, JobType } from '../models/job';

@Pipe({
  name: 'formatJobType'
})
export class FormatJobTypePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return jobTypeToName[value as JobType] || '';
  }

}
