import { Component, NgZone, ViewChild } from '@angular/core';

import { Platform, IonRouterOutlet, NavController } from '@ionic/angular';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment';
import { OfflineNetworkService } from './services/offline-network.service';
import { PubSubService } from './services/pub-sub.service';
import { ShowStoreLinksService } from './services/show-store-links.service';
import { UrlHistoryService } from './services/url-history.service';
import { SplitService } from './services/split.service';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { NavigationPlugin } from '@plugins/navigation/navigation-plugin';
import { App } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { AppRoutingUtil } from './util/app-routing-util';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  @ViewChild(IonRouterOutlet, { static: true })
  private routerOutlet: IonRouterOutlet;

  constructor(
    private readonly platform: Platform,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly offlineNet: OfflineNetworkService,
    private readonly showStoreLinks: ShowStoreLinksService,
    private readonly pubsub: PubSubService,
    private readonly navCtrl: NavController,
    private readonly urlHistory: UrlHistoryService,
    private readonly splitService: SplitService,
    private readonly titleService: Title,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService
  ) {
    this.initializeApp();

    // This gets the page title from the routers to display on web.
    // Used by Marketing for tracking using Google Tag Manager
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
              if (child.firstChild) {
                  child = child.firstChild;
              } else if (child.snapshot.data &&    child.snapshot.data['title']) {
                  return child.snapshot.data['title'];
              } else {
                  return null;
              }
          }
          return null;
          })
      ).subscribe( (data: any) => {
          if (data) {
              this.titleService.setTitle('Bluecrew - ' + data);
          }
      });
  }

  async initializeApp() {
    await this.platform.ready();
    if (this.platform.is('hybrid')) {
      if (this.platform.is('ios')) {
        try {
          await StatusBar?.setStyle({
            style: Style.Light
          });
        } catch { }
      } else {
        try {
          await StatusBar?.setBackgroundColor({ color: '#3246f0' });
        } catch { }
      }
    } else {
      if (!environment.IS_LOCALHOST) {
        // Conditionally build and add Google tag manager to only run in web.
        // Google Instructions: https://developers.google.com/tag-platform/tag-manager/web
        // Verification of Google Tag Manager: https://www.optimizesmart.com/check-if-google-tag-manager-is-working/
        const headScriptNode = document.createElement('script');
        const bodyNoScriptNode = document.createElement('noscript');
        headScriptNode.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TXZ5PT');`;
        bodyNoScriptNode.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TXZ5PT"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        document.getElementsByTagName('head')[0].appendChild(headScriptNode);
        document.getElementsByTagName('body')[0].appendChild(bodyNoScriptNode);
      }
    }
    SplashScreen?.hide();
    NavigationPlugin?.addListener('goToIonicScreen', info => {
      // TODO accept direction as well so we can do root v back v forward navigation
      this.zone.run(() => {
        if (this.router.url === info.path || !info.path) {
          // XXX: One day this might not be 100% desirable...for now works pretty well.
          this.pubsub.notifyForeground();
        } else {
          // Direct users to native schedule page.
          this.navCtrl.navigateForward(info.path);
        }
      });
    });
    // Deeplinking: Direct the App to the correct url.
    App.addListener('appUrlOpen', (event: any) => {
      this.zone.run(() => {
        if (event && event.url) {
          const url = new URL(event.url);
          const path = url.pathname + url.search;

          this.navCtrl.navigateForward(path);
        }
      });
    });
    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (!this.routerOutlet.canGoBack()) {
        console.debug("We could exit the app now to 'honor' the back button press")
        //App.exitApp();
      }
    });
    this.userService.user.subscribe(user => {
      if (user?.userInfo?.status === 'FIRED' || user?.userInfo?.status === 'SUSPENDED') {
        this.navCtrl.navigateRoot(AppRoutingUtil.USER_TERMINATED);
      }
    })
  }
}
