import _ from 'lodash';

export class MapUtils {
    static removeKeyInMap<K extends Object, V>(map: Map<K, V>, key: K) {
        const newMap = new Map();

        for (const [k, v] of map.entries()) {
            if (!_.isEqual(key, k)) {
                newMap.set(k, v);
            }
        }

        return newMap;
    }

    static findKeyInMap<K extends Object, T>(map: Map<K, T>, shiftKey: K): T | null {
        for (const key of map.keys()) {
            if (_.isEqual(key, shiftKey)) {
                return map.get(key);
            }
        }

        return null;
    }
}
