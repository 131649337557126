import { Storage } from "@ionic/storage";
import _ from "lodash";

export const BROWSE_JOBS_STORAGE_KEY = 'browseJobsPreference';
export const BROWSE_JOBS_LIST_STORAGE_VALUE = 'list';
export const BROWSE_JOBS_MAP_STORAGE_VALUE = 'map';

export const BLUESHIFT_BANNER_MINIMIZED_STORAGE_KEY = 'blueshiftBannerMinimized';

export const RELOAD_POSITIONS_KEY = 'reloadPositions';
export const LAST_POSITION_UPDATE_DISMISSAL_TIMESTAMP_KEY = 'lastPositionUpdateDismissalTime';
export const POSITION_UPDATE_TAKEOVER_TTL_MILLIS = 1800000; // 30 minutes

// storage key to set time user session was updated
export const USER_SESSION_UPDATE_KEY = 'userSessionUpdate';
// time frame used to update user session
export const USER_SESSION_UPDATE_TIME_MILLIS = 3600000;

// Terms of use
export const LAST_TERMS_OF_USE_CHECK_TIME_KEY = 'lastToUCheckTime';
export const TERMS_OF_USE_TTL_MILLIS = 86400000; // 1 day

// Takeover Screen
export const TAKEOVER_SCREEN_CHECK_PREFIX = 'takeoverScreenExpiry-';
export function generateTakeoverScreenCheckExpiryName(type: string) {
    return TAKEOVER_SCREEN_CHECK_PREFIX + type;
}

function jsonParserWithMap(key, value) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}

export function jsonStringifierWithMap(key, value) {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()),
        };
    } else {
        return value;
    }
}

export class StorageUtils {

    public static async getMap<K, V>(storage: Storage, key: string): Promise<Map<K, V>> {
        const value = await storage.get(key);

        if (_.isEmpty(value)) {
            return new Map();
        }

        return JSON.parse(value, jsonParserWithMap);
    }

    public static storeMap<K, V>(storage: Storage, key: string, map: Map<K, V>) {
        const json = JSON.stringify(map, jsonStringifierWithMap);

        return storage.set(key, json);
    }
}
