import { Injectable } from '@angular/core';
import { SplitFactory } from '@splitsoftware/splitio';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from '../services/user.service';
import { SegmentCMOSService } from '../services/segment-cmos.service';

@Injectable({
  providedIn: 'root'
})
export class SplitService {

  private segmentCMOS: SegmentCMOSService;
  private factory: SplitIO.ISDK;
  private client: SplitIO.IClient;

  constructor(private userService: UserService,
              private segmentCMOSService: SegmentCMOSService) {
    this.segmentCMOS = segmentCMOSService;
    this.userService.user.pipe(
      map(user => user?.userInfo?.externalId),
      distinctUntilChanged(isEqual)
    ).subscribe(userId => {
      try {
        if (userId) {
          // Instantiate the SDK
          this.factory = SplitFactory({
            core: {
              authorizationKey: environment.SPLIT.apiKey,
              // the key can be the logged in
              // user id, or the account id that
              // the logged in user belongs to.
              // The type of customer (user, account, custom)
              // is chosen during Split's sign-up process.
              key: userId as string
            },
            startup: {
              readyTimeout: 1.5 // 1.5 sec
            }
          });
          // And get the client instance you'll use
          this.client = this.factory.client();
        } else {
          // null userId implies user has logged out -> clean-up Split SDK
          this.client?.destroy();
          this.client = null;
        }
      } catch(err) {
        this.segmentCMOS.addEvent('split_initializationError', { reason: err.message });
      };
    });
  }

  /**
   * Determines if feature is enabled via Split
   * @param splitFeatureName Split feature name to check status for
   * @param defaultFeatureState Default return value if Split is not available
   * @param attributes Optional attributes to customize targeting rules
   * @returns returns 'true' if split feature is on or enabled - otherwise 'false'
   */
  async isFeatureEnabled(splitFeatureName: string, defaultFeatureState: boolean = false, attributes?: {}): Promise<boolean> {
    try {
      await this.client.ready();
      const allAttributes = this.addBaseAttributes(attributes);
      const treatment: SplitIO.Treatment = this.client.getTreatment(splitFeatureName, allAttributes);
      if (treatment === 'on') {
        return true;
      } else if (treatment === 'off') {
        return false;
      } else {
        return defaultFeatureState;
      }
    } catch(err) {
      this.segmentCMOS.addEvent('split_getTreatmentError', { reason: err.message });
      return defaultFeatureState;
    };
  }

  private addBaseAttributes(attributes: {}) {
    const baseAttributes = {
      'region_id': this.userService.currentUser?.userInfo?.personalInformation?.address?.regionId
    }

    // add app version attributes
    const appVersionString = environment.APP_VERSION;
    const appVersion = appVersionString.split(".");
    if (appVersion.length >= 2) {
      const appVersionKeys = {
          'majorAppVersion': appVersion[0],
          'minorAppVersion': appVersion[1]
      }
      Object.assign(baseAttributes, appVersionKeys);
    }

    return Object.assign({}, attributes, baseAttributes)
  }
}
