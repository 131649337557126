import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  UrlTree,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
/*
TL;DR;
/schedule?confirm-job=1 -> /confirm-job
(Web) /schedule -> /
(Native) /schedule -> (Opens schedule page)

ScheduleJobConfirmationGuard has a unique responsibility in that it is here to assist with an
upgrade to redirect users utilizing an SMS deep link to the correct page.  So the current deep
link that exists in confirmation SMS messages is /schedule which currently opens up the native
app Schedule page. In order to for the SMS messages to be backwards compatible out of the we've
added a query parameter of confirm-job=1 to be an indicator that it should be rerouted to the
/confirm-job page.  This guard is setup to look for that query parameter and determine what page
that it should route to. If it exists it will rely on settings in app-routing.module.ts to 
redirect to /confirm-job, if it's on web there is no native schedule page so it'll go to the 
root, if it's on native the native schedule page will be opened up (this case should not arise
as app.component.ts handles this case).

ALSO SEE app.component.ts FOR HOW DEEP LINKS ARE HANDLED. 
*/
export class ScheduleJobConfirmationGuard implements CanActivate {
  constructor(private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<UrlTree | boolean> {
    if (route.queryParams['confirm-job'] === '1') {
      return this.router.parseUrl('/confirm-job');
    }
    return true;
  }
}
