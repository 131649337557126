import { Injectable } from '@angular/core';

/**
 * Abstract logger used as an Interface for Angular Dependency Injection
 *
 * ref: https://angular.io/guide/dependency-injection-in-action#class-interface
 */

@Injectable({
  providedIn: 'root'
})
export abstract class Logger {

  protected constructor() {}

  setContext(context: LoggerContext) {}

  debug(...message: any) {}

  log(...message: any) {}

  warn(...message: any) {}

  error(...message: any) {}
}

export interface LoggerContext {
  userId: string
}
