import _ from 'lodash';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { CmosTimestampsService } from '../cmos-timestamp.service';
import { CreateTimestampRequest } from '@bluecrew/crew-member-backend-angular-client';
import { MapUtils } from 'src/app/util/map-utils';
import { StorageUtils } from 'src/app/util/storage-util';
import { createShiftStorageKey, ShiftStorageKey } from './common';
import { Network } from '@capacitor/network';

@Injectable({ providedIn: 'root' })
export class OfflineAwareTimestampService {

    public static readonly STORAGE_KEY = 'SHIFT_TIMESTAMPS';

    constructor(
        private readonly timestampService: CmosTimestampsService,
        private readonly storage: Storage) {
    }

    async addTimestampToShift(externalJobId: string, shiftIndex: number, request: CreateTimestampRequest): Promise<void> {
        const networkStatus = await Network.getStatus();

        if (networkStatus.connected) {
            request.isOffline = false;
            await this.timestampService.addTimestampToShift(externalJobId, shiftIndex, request);
        } else {
            const shiftKey = createShiftStorageKey(externalJobId, shiftIndex);
            let storedTimestampsMap = await StorageUtils.getMap<ShiftStorageKey, CreateTimestampRequest[]>
                (this.storage, OfflineAwareTimestampService.STORAGE_KEY);
            let storedShiftTimestamps = MapUtils.findKeyInMap(storedTimestampsMap, shiftKey);
            request.isOffline = true;

            if (_.isNil(storedShiftTimestamps)) {
                storedShiftTimestamps = [];
            } else {
                storedTimestampsMap = MapUtils.removeKeyInMap(storedTimestampsMap, shiftKey);
            }
            storedShiftTimestamps.push(request);
            storedTimestampsMap.set(shiftKey, storedShiftTimestamps);

            await StorageUtils.storeMap(this.storage, OfflineAwareTimestampService.STORAGE_KEY, storedTimestampsMap);
        }
    }
}
