// Usage: Can cast a ApiV1Job#jobType to this enum after checking that the
// key exists e.g. `if (JobType[apiV1Job.jobType]) useJobType(apiV1Job.jobType as JobType);`
export enum JobType {
    UNKNOWN = 1,
    COMMERCIAL_MOVING = 2,
    WAREHOUSE_WORK = 3,
    OFFICE_WORK = 4,
    CUSTOMER_SUPPORT = 5,
    FORKLIFT = 6,
    DELIVERY_AND_PICKUP = 7,
    TRUCK_DRIVER = 8,
    EVENT_STAFF = 9,
    JANITORIAL_WORK = 10,
    FOOD_SERVICE = 11,
    IT_WORK = 12,
    DRIVERS = 13,
    BARTENDING = 14,
    PRODUCTION_FOOD = 15,
    PRODUCTION_NON_FOOD = 16,
    HOUSEKEEPER = 17,
    RETAIL = 18,
    EVENT_SERVER = 19,
    CONCESSION_STAND_WORKER = 20,
    COOK = 21,
    DISHWASHER = 22,
    SERVER = 23
}

export const jobTypeToName: { [key in JobType]: string|undefined } = {
    [JobType.UNKNOWN]: undefined,
    [JobType.COMMERCIAL_MOVING]: 'Commercial Moving',
    [JobType.WAREHOUSE_WORK]: 'Warehouse Work',
    [JobType.OFFICE_WORK]: 'Office Work',
    [JobType.CUSTOMER_SUPPORT]: 'Customer Support',
    [JobType.FORKLIFT]: 'Forklift',
    [JobType.DELIVERY_AND_PICKUP]: 'Delivery and Pickup',
    [JobType.TRUCK_DRIVER]: 'Truck Driver',
    [JobType.EVENT_STAFF]: 'Event Staff',
    [JobType.JANITORIAL_WORK]: 'Janitorial Work',
    [JobType.FOOD_SERVICE]: 'Food Service',
    [JobType.IT_WORK]: 'IT Work',
    [JobType.BARTENDING]: 'Bartending',
    [JobType.DRIVERS]: 'Drivers',
    [JobType.PRODUCTION_FOOD]: 'Food Production',
    [JobType.PRODUCTION_NON_FOOD]: 'Production',
    [JobType.HOUSEKEEPER]: 'Housekeeper',
    [JobType.RETAIL]: 'Retail',
    [JobType.EVENT_SERVER]: 'Event Server',
    [JobType.CONCESSION_STAND_WORKER]: 'Concession Worker',
    [JobType.COOK]: 'Cook',
    [JobType.DISHWASHER]: 'Dishwasher',
    [JobType.SERVER]: 'Server'
};

export const jobTypeToIcon: { [key in JobType]: string } = {
    [JobType.UNKNOWN]: undefined,
    [JobType.COMMERCIAL_MOVING]: '/assets/icons/moving.svg',
    [JobType.WAREHOUSE_WORK]: '/assets/icons/warehouse.svg',
    [JobType.OFFICE_WORK]: '/assets/icons/office.svg',
    [JobType.CUSTOMER_SUPPORT]: '/assets/icons/customer.svg',
    [JobType.FORKLIFT]: '/assets/icons/forklift.svg',
    [JobType.DELIVERY_AND_PICKUP]: '/assets/icons/delivery.svg',
    [JobType.TRUCK_DRIVER]: '/assets/icons/truckdriver.svg',
    [JobType.EVENT_STAFF]: '/assets/icons/event.svg',
    [JobType.JANITORIAL_WORK]: '/assets/icons/janitorial.svg',
    [JobType.FOOD_SERVICE]: '/assets/icons/foodservice.svg',
    [JobType.IT_WORK]: '/assets/icons/itsupport.svg',
    [JobType.DRIVERS]: '/assets/icons/truckdriver.svg',
    [JobType.BARTENDING]: '/assets/icons/bartending.svg',
    [JobType.PRODUCTION_FOOD]: '/assets/icons/job_type/production-food.svg',
    [JobType.PRODUCTION_NON_FOOD]: '/assets/icons/job_type/production-non-food.svg',
    [JobType.HOUSEKEEPER]: '/assets/icons/job_type/housekeeper.svg',
    [JobType.RETAIL]: '/assets/icons/job_type/retail.svg',
    [JobType.EVENT_SERVER]: '/assets/icons/job_type/event-server.svg',
    [JobType.CONCESSION_STAND_WORKER]: '/assets/icons/job_type/concession-stand-worker.svg',
    [JobType.COOK]: '/assets/icons/job_type/cook.svg',
    [JobType.DISHWASHER]: '/assets/icons/job_type/dishwasher.svg',
    [JobType.SERVER]: '/assets/icons/job_type/server.svg'
};
