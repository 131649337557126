import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserService } from '../services/user.service';

/**
 * Guard to conditionally redirect users to different routes based on their state/context
 */
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private userService: UserService
    ) {}

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        await this.userService.ready();

        if (await this.userService.getCognitoUser() || this.userService.currentUser?.userInfo?.phoneVerified) return true;  // fully authed user; take 'em where they were going.

        if (this.userService.currentUser) {
            // Partially authed user. By default take them to the verification-code screen or resend-code screens
            return state.url.includes('/account/verification-code') || state.url.includes('/account/resend-code') || this.router.parseUrl('/account/verification-code');
        }
        this.userService.redirectUrl = state.url;
        return this.router.parseUrl('/home');
    }
}
