import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, timer, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { retryWhen, mergeMap, map } from 'rxjs/operators';

/**
 * Interceptor for retrying failed HTTP Requests
 */
@Injectable()
export class RetriesInterceptor implements HttpInterceptor {
    static readonly retriesConfig = {
        retryCount: 1,
        methods: ['GET'],
        delay: 500,
        linearBackoffFactor: 1.5,
    };
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!RetriesInterceptor.retriesConfig.methods.includes(request.method.toUpperCase())) {
            return next.handle(request);
        }
        return next.handle(request).pipe(
            retryWhen(errors => errors.pipe(
                mergeMap((error, retryCount) => {
                    if (retryCount < RetriesInterceptor.retriesConfig.retryCount && 499 < error?.status && error?.status < 600) {
                        // Linear backoff
                        const delay = getDelay(retryCount, RetriesInterceptor.retriesConfig.linearBackoffFactor, RetriesInterceptor.retriesConfig.delay);
                        return timer(delay).pipe(
                            map(_ => error),
                        );
                    } else {
                        return throwError(error);
                    }
                }),
            )),
        );
    }
}

export function getDelay(retryCount: number, backoffFactor: number, delay: number): number {
    return Math.max(1, retryCount * backoffFactor) * delay;
};