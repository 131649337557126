import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppStoreLinksPage } from '../app-store-links/app-store-links.page';
import { NavigationPlugin } from '@plugins/navigation/navigation-plugin';

@Injectable({
  providedIn: 'root'
})
export class ShowStoreLinksService {

  private isShowing = false;

  constructor(private modalCtrl: ModalController) {
    NavigationPlugin['onUnhandledNavigation'] = this.onUnhandledNavigation;
  }

  private onUnhandledNavigation = async (options: { path: string }) => {
    if (this.isShowing) return;
    this.isShowing = true;
    const modal = await this.modalCtrl.create({
      component: AppStoreLinksPage,
      cssClass: 'bottom-sheet-modal',
    });
    await modal.present();
    await modal.onDidDismiss();
    this.isShowing = false;
  };
}
