import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import _ from 'lodash';
import { CreateTimestampRequest } from '@bluecrew/crew-member-backend-angular-client';
import { __importStar } from 'tslib';
import { presentSuccessToast, presentToast } from '../util/ion-util';
import { OfflineAwareTimestampService } from './clients/offline-aware-timestamp.service';
import { StorageUtils } from '../util/storage-util';
import { CmosTimestampsService } from './cmos-timestamp.service';
import { ShiftStorageKey } from './clients/common';

export abstract class TimestampSyncer {
    abstract commit(): Promise<void>;
}

@Injectable({ providedIn: 'root' })
export class OfflineTimestampSyncer extends TimestampSyncer {

    constructor(
        private readonly storage: Storage,
        private readonly timestampService: CmosTimestampsService,
        private readonly toastController: ToastController
    ) {
        super();
    }

    async commit(): Promise<void> {
        const storedTimestamps = await StorageUtils.getMap<ShiftStorageKey, CreateTimestampRequest[]>
            (this.storage, OfflineAwareTimestampService.STORAGE_KEY)
        const addTimestampToShiftPromises = [];

        if (_.isEmpty(storedTimestamps)) {
            return;
        }

        for (const [key, value] of storedTimestamps.entries()) {
            for (const timestamp of value) {
                timestamp.isOffline = true;
                addTimestampToShiftPromises.push(
                    this.timestampService.addTimestampToShift(key.externalJobId, key.shiftIndex, timestamp)
                );
            }
        }

        const settledPromises = await Promise.allSettled(addTimestampToShiftPromises);
        const [fulfilled, rejected] = _.partition(settledPromises, ['status', 'fulfilled']);

        await StorageUtils.storeMap(this.storage, OfflineAwareTimestampService.STORAGE_KEY, new Map());
        if (!_.isEmpty(fulfilled)) {
            await presentSuccessToast(
                this.toastController,
                `${fulfilled.length} timestamps were submitted from offline usage.`);
        }
        if (!_.isEmpty(rejected)) {
            await presentToast(
                this.toastController,
                'error-toast',
                `${rejected.length} timestamps failed for submission. Contact support.`,
                5000,
                'bottom'
            )
        }
    }
}
