// TODO - Right now this is just a copy of what API-V1 returns
// TODO - from the signin API. We should separate what the API
// TODO - returns and what we pass around internally.

import { UserInformation } from "@bluecrew/crew-member-backend-angular-client";

// TODO - Something like APIV1User and User classes


export interface UserInfo {
    userInfo: UserInformation
}

export interface LoginResponse extends UserInfo {
    authToken: string;
    jwtToken: string;
}

export interface User extends UserInfo {
    apiV1Token: string;
    cmosToken: string;
};

export interface IntercomInfo {
    email: string;
    // level + level_status excluded by user/status
    // level: string;
    // level_status: string;
    name: string;
    payroll: string;
    phone_number: string;
    profile: string;
    region: string;
    status: string;
    type?: UserType;
    user_id: string;
}

export interface SignUpResponse {
    authToken: string;
    email: string;
    externalId: string;
    first_name: string;
    id: number;
    last_name: string;
    phone_number: string;
    zipcode: string;
}

export interface SignUpRequest {
    first_name: string;
    last_name: string;
    source: string;
    stageId: string;
    zipcode: string;
    email: string;
    password: string;
    phone_number: string;
    referrer: string;
    referrerCode: string;
    branchParams: {
        utmSource?: string;
        utmMedium?: string;
    };
}

export enum UserType {
    BLUECREW,
    ADMIN,
    CLIENT_ADMIN,
    CLIENT,
    MANAGER,
    FIELD,
    RECRUITER,
    PAYROLL_ADMIN,
    SUPER_ADMIN
}
