import { LoadingController, Platform, ToastController } from '@ionic/angular';
import { NavigationPlugin } from '@plugins/navigation/navigation-plugin';

export const ANDROID_STORE_URL = 'https://play.google.com/store/apps/details?id=com.bluecrewjobs.bluecrew';

export const APPLE_STORE_URL = 'https://apps.apple.com/us/app/bluecrew/id1033694488';

export const presentLoader = async (loadingCtrl: LoadingController, timeoutMillis = 30000): Promise<HTMLIonLoadingElement> => {
    const loader = await loadingCtrl.create({
        duration: timeoutMillis
    });
    await loader.present();
    return loader;
};

export const dismissLoaderObserver = (loader: HTMLIonLoadingElement) => ({
    next: () => loader.dismiss(),
    error: () => loader.dismiss(),
});

/**
 * @deprecated The method should not be used instead use presentErrorToast
 */
export const presentToast = async (toastCtrl: ToastController, cssClass: string, message: string, duration: number, position: any): Promise<HTMLIonToastElement> => {
    return await presentErrorToast(toastCtrl, message, cssClass, duration, position);
}


export const presentGenericToast = async (
  toastCtrl: ToastController,
  message: string,
  cssClass: string,
  duration: number,
  position: any
): Promise<HTMLIonToastElement> => {
  // Stop multiple toasts
  try {
    await toastCtrl.dismiss();
  } catch (err) {}

  const toast = await toastCtrl.create({
    cssClass,
    message,
    duration,
    position,
  });
  await toast.present();
  return toast;
};

export const presentErrorToast = async (
  toastCtrl: ToastController,
  message: string,
  cssClass: string = 'error-toast',
  duration: number = 1500,
  position: any = 'bottom'
): Promise<HTMLIonToastElement> => {
  return await presentGenericToast(
    toastCtrl, 
    `<img src="assets/icons/alert-triangle.svg"></img>  ${message}`,
    cssClass,
    duration,
    position
  );
};

export const presentSuccessToast = async (
  toastCtrl: ToastController,
  message: string,
  cssClass: string = 'success-toast',
  duration: number = 5000,
  position: any = 'bottom'
): Promise<HTMLIonToastElement> => {
  return await presentGenericToast(
    toastCtrl, 
    `<img src="assets/icons/completed-check.svg"> ${message}`,
    cssClass,
    duration,
    position
  );
};

export function openCorrectAppStore(platform: Platform, { isRedirect = false } = {}, appStoreToOpen: string): void {
    let url: string;
    if (platform.is('ios')) {
        url = APPLE_STORE_URL;
    } else if (platform.is('android')){
        url = ANDROID_STORE_URL;
    } else {
        url = appStoreToOpen === 'ios' ? APPLE_STORE_URL : ANDROID_STORE_URL
    }
    if (isRedirect) {
        location.replace(url);
    } else {
        window.open(url);
    }
};

export function isAffirmative(text: string | undefined | null): boolean {
    text = text || '';
    const affirmativeWords = ['1', 'yes', 'y', 'true', 'on'];
    return affirmativeWords.includes(text.toLowerCase().trim());
}

export function openIntercom(message?: string) {
    if (this.platform.is('hybrid')) {
      NavigationPlugin?.goToNativeScreen({
        path: 'Help',
      });
    } else {
      (window as any).Intercom('showNewMessage', message);
    }
}
