import { isNumber } from 'lodash-es';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AnalyticsPlugin } from '@plugins/analytics/analytics-plugin';

@Injectable({
  providedIn: 'root'
})
export class MetricsInterceptorService {

  constructor() { }

  instrumentHttpRequest(prefix: string, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const uri = new URL(request.url).pathname;
    const method = request.method;
    const start = Date.now();
    return next.handle(request).pipe(
      tap(res => {
        if (res instanceof HttpResponse) {
          AnalyticsPlugin.logEvent({
            name: `http_${prefix}_success`,
            params: {
              uri,
              method,
              timer: `${Date.now() - start}`,
            },
          });
        }
      }),
      catchError(err => {
        const code = err.status;
        if (isNumber(code) && code > 0) {
          AnalyticsPlugin.logEvent({
            name: `http_${prefix}_${Math.floor(code / 100) === 4 ? 'clientexception' : 'error'}`,
            params: {
              uri,
              method,
              timer: `${Date.now() - start}`,
            },
          });
        }
        return throwError(err);
      }),
    );
  }
}
