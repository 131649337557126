import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import { AppRoutingUtil } from '../util/app-routing-util';

/**
 * HttpInterceptor to handle responses from server-side. This one in particular is to read minimum
 * app version from the header and see if CM's app version is higher. Navigate to update-app page if not
 */
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    constructor(private navCtrl: NavController) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((httpEvent: any) => {
            if (httpEvent instanceof HttpResponse) {
                if (httpEvent.headers.has('X-bluecrew-min-version-code')) {
                    const header = httpEvent.headers.get('x-bluecrew-min-version-code');
                    if (!this.checkIfMinimumAppVersion(header)) {
                        this.navCtrl.navigateRoot(AppRoutingUtil.UPDATE_APP);
                    }
                }
            }
        }));
    }

    checkIfMinimumAppVersion(minVersionCode: string): boolean {
        const currentAppVersion = `${environment.APP_VERSION}`;
        // if we can't read the app version from either CM or the header do nothing
        if (isNil(minVersionCode)) {
            return true;
        }
        const currentVersionCode = currentAppVersion.split(".")[1];
        return parseInt(currentVersionCode) >= parseInt(minVersionCode);
    }
}
