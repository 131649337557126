import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { isNumber } from 'lodash-es';
import { UserService } from '../services/user.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger } from '../util/logger/logger.service';
import { PubSubService } from '../services/pub-sub.service';

/**
 * HttpInterceptor to handle top level errors and redirect accordingly before returning to original callers
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private static readonly LOGOUT_HTTP_CODES = [401, 403];

    constructor(private logger: Logger,
                private userService: UserService,
                private pubsub: PubSubService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {
            if (isNumber(err.status)) {
                if (ErrorInterceptor.LOGOUT_HTTP_CODES.includes(err.status) && this.userService.currentUser && this.userService.currentUser.userInfo.type === '0') {
                    this.userService.logout();
                }
                if (err.status === 0) {
                    this.pubsub.notifyOffline();
                }
                if (err.status >= 200) {
                    this.pubsub.notifyOnline();
                }
            }
            this.logger.error(err);
            return throwError(err);
        }));
    }
}
