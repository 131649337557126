import { Injectable } from '@angular/core';
import {
  BatchTimestampModificationRequest,
  CreateTimestampRequest,
  JobsService as CmbJobsApi,
  TimestampsService as CmbTimestampsApi,
  EditTimestampReasonsResponse
} from '@bluecrew/crew-member-backend-angular-client';
import { LoadingController } from '@ionic/angular';
import { SegmentCMOSService } from './segment-cmos.service';
import { AnalyticsPlugin } from '@plugins/analytics/analytics-plugin';
import { presentLoader } from '../util/ion-util';

@Injectable({
  providedIn: 'root',
})
export class CmosTimestampsService {
  private static readonly LOADER_TIMEOUT_MILLIS = 10000;
  private editTimestampReasonsResponse: EditTimestampReasonsResponse;

  constructor(
    private readonly cmbJobsApi: CmbJobsApi,
    private readonly cmbTimestampsApi: CmbTimestampsApi,
    private readonly loadingCtrl: LoadingController,
    private readonly segmentCMOS: SegmentCMOSService
  ) {}

  async addTimestampToShift(externalJobId: string, shiftIndex: number, request: CreateTimestampRequest) {
    const loader = await this.createLoader();
    await loader.present();
    try {
      return await this.cmbJobsApi.addTimestampToShift(externalJobId, shiftIndex, request).toPromise();
    } catch (error) {
      AnalyticsPlugin.logEvent({ name: 'addTimestampToShift_error', params: { type: typeof error } });
      this.segmentCMOS.addEvent('addTimestampToShift_error', {
        reason: error?.error?.reason,
        details: error?.error?.details,
        message: error?.error?.message,
      });
      throw error;
    } finally {
      await loader.dismiss({ requestFinished: true });
    }
  }

  async getEditTimestampReasons() {
    if (!this.editTimestampReasonsResponse) {
      this.editTimestampReasonsResponse = await this.cmbTimestampsApi.getEditTimestampReasons().toPromise();
    }
    return this.editTimestampReasonsResponse;
  }

  async deleteTimestamp(externalJobId: string, shiftIndex: number, timestampId: string) {
    await this.cmbJobsApi.deleteTimestamp(externalJobId, shiftIndex, timestampId).toPromise();
  }

  async addBreak(externalJobId: string, shiftIndex: number, start: string, end: string, editReasonId?: string) {
    const request = {
      adds: [
        {
          time: start,
          type: 'LUNCH_START',
          isManuallyProvided: true,
          editReasonId,
        },
        {
          time: end,
          type: 'LUNCH_END',
          isManuallyProvided: true,
          editReasonId,
        },
      ],
    } as BatchTimestampModificationRequest;
    const loader = await presentLoader(this.loadingCtrl);
    try{
      await this.cmbJobsApi.batchTimestampUpdate(externalJobId, shiftIndex, request).toPromise();
    } finally {
      await loader.dismiss();
    }
  }

  private async createLoader() {
    const loader = await this.loadingCtrl.create({
      duration: CmosTimestampsService.LOADER_TIMEOUT_MILLIS,
    });
    loader.onDidDismiss().then((event) => {
      if (!event?.data?.requestFinished) {
        AnalyticsPlugin.logEvent({ name: 'addTimestampToShift_loaderTimeout', params: {} });
        this.segmentCMOS.addEvent('addTimestampToShift_loaderTimeout');
      }
    });
    return loader;
  }
}
