import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './interceptors/auth.guard';
import { BrowseJobsGuard } from './interceptors/browse-jobs.guard';
import { ContactSupportGuard } from './interceptors/contact-support.guard';
import { ScheduleJobConfirmationGuard } from './interceptors/schedule-job-confirmation.guard';
import { UserTerminatedGuard } from './interceptors/user-terminated.guard';
import { AppRoutingUtil } from './util/app-routing-util';
import { TakeoverScreenGuard } from './interceptors/takeover-screen.guard';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [UserTerminatedGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
        canActivate: [ TakeoverScreenGuard ],
        data: { title: 'Dashboard' },
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule),
        data: { title: 'Home' },
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'assessment',
        redirectTo: 'assessment/',
        pathMatch: 'full',
      },
      // Assessments
      {
        path: 'assessment/:assessmentResponseId',
        loadChildren: () => import('./assessment/assessment.module').then((m) => m.AssessmentPageModule),
      },
      {
        path: 'a/:assessmentResponseId',
        loadChildren: () => import('./assessment/assessment.module').then((m) => m.AssessmentPageModule),
      },
      // -- end
      {
        path: 'employment-info',
        loadChildren: () => import('./employment-info/employment-info.module').then(m => m.EmploymentInfoPageModule)
      },
      {
        path: 'application-home',
        loadChildren: () => import('./application-home/application-home.module').then((m) => m.ApplicationHomePageModule),
        canActivate: [ TakeoverScreenGuard ],
        data: { title: 'Get This Job' },
      },
      {
        path: 'offline',
        loadChildren: () => import('./offline/offline.module').then((m) => m.OfflinePageModule),
      },
      {
        path: 'legal',
        loadChildren: () => import('./legal/legal.module').then((m) => m.LegalPageModule),
        data: { title: 'Legal' },
      },
      {
        path: 'signature',
        loadChildren: () => import('./signature/signature.module').then((m) => m.SignaturePageModule),
      },
      {
        path: 'i9',
        loadChildren: () => import('./i9/i9.module').then((m) => m.I9PageModule),
      },
      {
        path: 'onaroll',
        loadChildren: () => import('./onaroll/onaroll.module').then((m) => m.OnarollPageModule),
      },
      {
        path: 'browse-jobs',
        loadChildren: () => import('./browse-jobs/browse-jobs.module').then((m) => m.BrowseJobsModule),
        canActivate: [BrowseJobsGuard],
        data: { title: 'Browse Jobs List' },
      },
      {
        path: `${AppRoutingUtil.getRouteForRoot(AppRoutingUtil.JOB_ROUTE_PREFIX)}${AppRoutingUtil.JOB_ROUTE_SUFFIX}`,
        loadChildren: () => import('./position/position.module').then((m) => m.PositionPageModule),
      },
      {
        path: `${AppRoutingUtil.getRouteForRoot(AppRoutingUtil.POSITION_JOB_ROUTE_PREFIX)}${AppRoutingUtil.POSITION_JOB_ROUTE_SUFFIX}`,
        loadChildren: () => import('./position/position.module').then((m) => m.PositionPageModule),
        data: { title: 'Job Detail' },
      },
      {
        path: `${AppRoutingUtil.getRouteForRoot(AppRoutingUtil.POSITION_ROUTE_PREFIX)}${AppRoutingUtil.POSITION_ROUTE_SUFFIX}`,
        loadChildren: () => import('./position/position.module').then((m) => m.PositionPageModule),
      },
      {
        path: `${AppRoutingUtil.getRouteForRoot(AppRoutingUtil.POSITION_ROUTE_PREFIX)}${AppRoutingUtil.POSITION_ROUTE_SUFFIX}${
          AppRoutingUtil.POSITION_MULTI_SELECT
        }`,
        loadChildren: () => import('./position/position.module').then((m) => m.PositionPageModule),
      },
      {
        path: `${AppRoutingUtil.getRouteForRoot(AppRoutingUtil.UPDATE_APP)}`,
        loadChildren: () => import('./update-app/update-app.module').then((m) => m.UpdateAppModule),
      },
      {
        path: 'go-to-app-store',
        loadChildren: () => import('./go-to-app-store/go-to-app-store.module').then((m) => m.GoToAppStorePageModule),
      },
      {
        path: 'browse-jobs-map',
        loadChildren: () => import('./browse-jobs-map/browse-jobs-map.module').then((m) => m.BrowseJobsMapModule),
        canActivate: [BrowseJobsGuard],
        data: { title: 'Browse Jobs Map' },
      },
      {
        path: 'secureassets',
        loadChildren: () => import('./secureassets/secureassets.module').then((m) => m.SecureassetsPageModule),
      },
      {
        path: 'app-store-links',
        loadChildren: () => import('./app-store-links/app-store-links.module').then((m) => m.AppStoreLinksPageModule),
      },
      {
        path: 'application-home/post-submit',
        loadChildren: () => import('./application-home/post-submit/post-submit.module').then((m) => m.PostSubmitPageModule),
      },
      {
        path: 'confirm-job',
        loadChildren: () => import('./confirm-job/confirm-job.module').then((m) => m.ConfirmJobPageModule),
      },
      {
        path: 'schedule',
        canActivate: [ScheduleJobConfirmationGuard],
        loadChildren: () => import('./schedule/schedule.module').then((m) => m.SchedulePageModule),
      },
      {
        path: `${AppRoutingUtil.getRouteForRoot(AppRoutingUtil.CANCEL_JOB_PREFIX)}${AppRoutingUtil.CANCEL_JOB_SUFFIX}`,
        loadChildren: () => import('./cancel-job/cancel-job.module').then((m) => m.CancelJobPageModule),
      },
      {
        path: 'cancel-job/:jobId/:shiftId',
        loadChildren: () => import('./cancel-job/cancel-job.module').then((m) => m.CancelJobPageModule),
      },
      {
        path: `${AppRoutingUtil.getRouteForRoot(AppRoutingUtil.CANCEL_SHIFT_PREFIX)}${AppRoutingUtil.CANCEL_SHIFT_INTER}${
          AppRoutingUtil.CANCEL_SHIFT_SUFFIX
        }`,
        loadChildren: () => import('./cancel-shift/cancel-shift.module').then((m) => m.CancelShiftPageModule),
      },
      {
        path: 'send-message',
        loadChildren: () => import('./send-message/send-message.module').then((m) => m.SendMessagePageModule),
      },
      {
        path: 'position-updates',
        loadChildren: () => import('./position-updates/position-updates.module').then((m) => m.PositionUpdatesPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: `${AppRoutingUtil.getRouteForRoot(AppRoutingUtil.SHIFT_DETAIL_PREFIX)}${AppRoutingUtil.SHIFT_DETAIL_INTER}${
          AppRoutingUtil.SHIFT_DETAIL_SUFFIX
        }`,
        loadChildren: () => import('./shift/shift.module').then((m) => m.ShiftPageModule),
      },
      {
        path: 'user-info',
        canActivate: [AuthGuard],
        loadChildren: () => import('./user-info/user-info.module').then((m) => m.UserInfoPageModule),
      },
      {
        path: 'position-updates',
        loadChildren: () => import('./position-updates/position-updates.module').then((m) => m.PositionUpdatesPageModule),
      },
      {
        path: 'help',
        canActivate: [AuthGuard, ContactSupportGuard],
        children: [],
      },
      {
        path: 'strike-policies',
        loadChildren: () =>
          import('./user-info/performance/strike-policies/strike-policies.module').then((m) => m.StrikePoliciesPageModule),
      },
      {
        path: 'acknowledge-strikes',
        loadChildren: () => import('./acknowledge-strikes/acknowledge-strikes.module').then((m) => m.SuspendedPageModule),
      },
      {
        path: 'needs-action',
        loadChildren: () => import('./takeover-screen-handler/takeover-screen-handler.module').then( m => m.TakeoverScreenHandlerPageModule)
      },
      {
        path: 'terminated',
        canActivate: [TakeoverScreenGuard],
        loadChildren: () => import('./terminated/terminated.module').then( m => m.TerminatedPageModule)
      },
      {
        path: 'terms-of-use',
        loadChildren: () => import('./terms-of-use/terms-of-use.module').then( m => m.TermsOfUsePageModule)
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
