import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { TakeoversService as CmbTakeoversApi, TakeoverScreenType } from '@bluecrew/crew-member-backend-angular-client';
import { Storage } from '@ionic/storage';
import { UserService } from '../services/user.service';
import { generateTakeoverScreenCheckExpiryName } from '../util/storage-util';
import { AppRoutingUtil } from '../util/app-routing-util';

@Injectable({
  providedIn: 'root',
})
export class TakeoverScreenGuard implements CanActivate {
    constructor(
      private readonly cmbTakeoversApi: CmbTakeoversApi,
      private readonly storage: Storage,
      private readonly router: Router,
      private readonly userService: UserService
    ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {

    // bypass guard logic if user is not authenticated
    await this.userService.ready();
    if (!this.userService.currentUser) return true;

    // determine takeover screens that shouldn't be sent
    const skippedScreens = await this.getSkippedTakeoverScreens();

    // call CMB to retrieve takeover screens
    let screensToShowResponse;
    try {
      screensToShowResponse = await this.cmbTakeoversApi.getTakeoverScreens(state.url, skippedScreens).toPromise();
    } catch (ex) {
      return true; // navigate forward if we have any issues retrieving takeover screens
    }

    // loop through screens and present a component for each in the order they are returned by CMB
    if (screensToShowResponse?.screens?.length > 0) {
      return this.router.navigateByUrl(AppRoutingUtil.TAKEOVER_SCREEN, {
        state: {
          screensToShow: screensToShowResponse.screens,
          sourcePage: state.url
        }
      });
    }
    
    return true;
  }

  private async getSkippedTakeoverScreens(): Promise<Array<TakeoverScreenType>> {
    const skippedScreens = [];
    for (let type of Object.values(TakeoverScreenType)) {
      const nextCheckDate = await this.storage.get(generateTakeoverScreenCheckExpiryName(type));
      if (new Date(nextCheckDate) > new Date()) {
        skippedScreens.push(type);
      }
    }

    return skippedScreens;
  }
}
