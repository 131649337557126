import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatJobTypePipe } from './format-job-type.pipe';
import { SafeUrlPipe } from './safe-url.pipe';

@NgModule({
  declarations: [FormatJobTypePipe, SafeUrlPipe],
  imports: [
    CommonModule
  ],
  exports: [FormatJobTypePipe, SafeUrlPipe],
})
export class PipeModule { }
